@font-face {
    font-family: "product-sans-medium";
    src: url("../Fonts/FontsFree-Net-ProductSans-Medium.ttf");
}

$textcolor: #182d64;
$background: #f8f4ee;
$accent: #636363;
$highlight: #ff4a00;

.website-login-btn {
    color: $textcolor !important;
    float: right !important;
    background: #fff;
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
    width: auto;
    margin-top: -5px;
    padding: 7px;
    border: 1px solid #FF5C5D;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
}

.home-first {
    // background-position: right;
    background-repeat: no-repeat;
    background-size: 73.5% 100%;

    background-image: url("../images/Rectangle\ 10.png");
}

.inner-first {
    margin-top: 30%;
    margin-left: 19.5%;
    margin-bottom: 30%;

    h1 {
        font-style: normal !important;
        font-weight: 500 !important;
        font-family: "product-sans-medium", sans-serif !important;
        font-size: 35.5px !important;
        line-height: 1.1 !important;

    }

    h3 {
        margin-top: 30px !important;
    }
}

.second-box {
    margin-bottom: -4%;
    background: #FFEFF2;
    padding: 20px;
    box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 16px;
    margin-left: 4%;
    margin-right: 3%;

    h6 {
        color: $textcolor;
        font-weight: bolder;
    }

    span {
        color: #7681a1;
    }
}

.credlogo-background {
    background-image: url("../images/Group.png");
    background-position: 5% 289px;
    background-repeat: no-repeat;
}

.feature-tagline-heading {
    color: $textcolor;
    font-weight: bolder;
}

.feature-tagline-heading-color {
    color: $textcolor;
}

.sub-heading {
    color: $textcolor;
    font-size: 20px;
}

.bottom-color {
    width: 54px;
    height: 4px;
    background: #ff6a92;
    border-radius: 4px;
}

.community-bg-image {
    background-image: url("../images/Group 81.png");
    background-position: 1% 126px;
    background-repeat: no-repeat;
}

.ptag-text {
    color: #7681a2;
    margin-top: 10px;
    font-size: 16px !important;
}

.ptag-text-html-text {
    img {
        // width: 100 !important;
        // max-width: 30px;
        text-align: center;
    }

    p,
    span {
        color: #7681a2 !important;
        margin-top: 14px !important;
        margin-left: 1%;
        text-align: left;
        font-size: 16px !important;
    }

}

.elipse-blue {
    background: #182d64;
    height: 30px;
    width: 30px;
    border-radius: 40px !important;
}

.community-bg-image-second {
    background-image: url("../images/Group 81.png");
    background-position: 89% 5px;
    background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) and (max-width: 1379px) {
    .first-image-onboarding {
        background-size: 100% auto !important;
    }

    .u-shape-box {
        height: 550px !important;
    }

    .about-text {
        font-size: 25px !important;
    }

    .contact-box {
        width: 500px !important;
        // height: 430px !important;
    }

    .main-heading-onboarding {
        // font-size: 48px !important;
    }

    .second-heading-onboarding {
        font-size: 18px !important;
    }

}

@media screen and (min-width: 768px) and (max-width: 1223px) {


    .feature-tagline-heading-text {
        font-size: smaller !important;
    }

    .feature-tagline-heading-text {
        font-size: smaller !important;
    }

    .community-card {
        background-size: 77%, 26% !important;
        padding: 10px 30px !important;
        height: 235px !important;
    }

    .feature-tagline-heading {
        font-size: 16px !important;
    }

    .about-text {
        font-size: 20px !important;
    }
}

@media screen and (min-width: 575px) and (max-width: 767px) {

    .wrap-text {
        white-space: break-spaces
    }

    .feature-tagline-heading-text {
        font-size: 8px !important;
    }

    .feature-tagline-heading {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 768px) {
    .buisnessimage-card {
        margin-top: 20% !important;
    }

    // .loan-product-box {
    //     min-height: 400px !important;
    // }

    .text-handle {
        text-align: center !important;

    }

    .app-button {
        width: 20% !important;
        margin-top: 20px !important;
    }

    .download-mobile-image {
        position: relative;
    }



    .first-image-loan {
        margin-top: 1% !important;
    }

    .main-heading-fintech {
        font-size: 30px !important;
    }

    .second-heading-fintech {
        text-align: justify;
        font-size: 11px !important;
        color: $textcolor;
    }

    .dropdown-menu:after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid #fff;
        // right: 64% !important;
        top: -20px;
    }

    .dropdown-menu {
        width: 600px !important;
        left: -150px !important;
    }

    .nav-link {
        font-size: 15px !important;
        margin-left: 0 !important;

    }

    .navbar-nav {
        margin-left: 11px !important;
        margin-top: 5px !important;

    }

    .first-image-onboarding {
        background-size: 100% 60% !important;

    }

    .main-heading-onboarding {
        font-size: 30px !important;
    }

    .second-heading-onboarding {
        font-size: 13px !important;
    }

    .second-image-onboarding {
        margin-left: 5% !important;
        margin-top: 32% !important;
    }

    .third-image-onboarding {
        width: 450px !important;
    }

    .third-heading-text {
        margin-top: -30px !important;
        font-size: 22px !important;
    }

    .third-heading-text-sub {
        font-size: 15px !important;
    }

    .circle-heading {
        margin-top: 8px !important;
        width: 15px !important;
        height: 15px !important;
    }


    .contact-bg2 {
        background-size: auto !important;
        height: 731px !important;
    }

    .contact-box {
        width: 420px !important;
        // height: 400px !important;
    }

    .lender-margin-left-contact {
        margin-left: 15% !important;
    }

    // .feature-tagline-heading-text{

    //     font-size: 13px !important;
    // }
    .card-lenders {
        height: 282px !important;
    }

    .home-tabs {
        // width: auto !important;
        font-size: 15px !important;

        &:hover {
            outline: none;
            box-shadow: none;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .about-us-card-bottom {
        min-height: 250px !important;
    }

    .about-us-card {
        min-height: 302px !important;
    }

    .about-text {
        font-size: small !important;
    }

    .displaynone-craousel {
        display: none !important;
    }

    .lender-margin-left {
        margin-left: auto !important;
    }

    .community-card {
        width: auto !important;
        // margin-top: auto !important;
    }

    .heading-diversity {
        margin-top: 0px !important;
    }

    .inner-first {
        margin-left: 4%;
        margin-top: 25%;
    }

    .text-right-footer {
        text-align: left !important;
    }

    // .craousel-image {
    //     text-align: center;
    //     margin-top: 1% !important;
    //     margin-bottom: 2%;
    //     width: 45%;

    //     margin-left: 2%;
    // }
    .craousel-image2 {
        margin-left: -11%;
        margin-top: 1% !important;
        max-width: 61%;
    }

    .home-first {
        background-size: 74% 100%;
    }

    .top-content {

        // margin-top: 10% !important;
        // font-size: 9px !important;
        h5,
        h6,
        p {
            font-size: 10px !important;
        }
    }

    .navbar-collapse {
        background-color: #fff;
        color: #000 !important;
        border-radius: 5px;
    }

    .nav-link {
        color: #000 !important;
    }

    .feature-tagline-heading-top {
        font-size: 30px !important;
        color: $textcolor;
    }
}

.tab-bg {
    background: #f2f2fa;
    margin-right: auto;
    margin-left: auto;
    border-radius: 25px;
    width: fit-content;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
}

.tab-btn-active {
    background: #fff !important;

    // font-size: 10px !important;
    margin: 6px;

    &:focus {
        border: none !important;
        outline: none !important;
    }

    &:active {
        border: none !important;
        outline: none !important;
    }
}

.tab-btn {
    // font-size: 10px !important;
    margin: 6px;

    &:focus {
        border: none !important;
        outline: none !important;
    }

    &:active {
        border: none !important;
        outline: none !important;
    }
}

.second-last-div {
    background: #182d64;
    color: #fff;
    // padding: 6%;
    // margin-top: 20px;
}

.header-fixed {
    position: fixed !important;
    width: 100% !important;
    z-index: 99 !important;

}

.logo-top {
    width: 150px !important;
    // margin-left: 56%;
    // margin-top: -10px;
}

.logo-handle {

    position: fixed;
    margin-left: 44%;


}

.navbar-nav {
    // margin-left: 90px;
    border-radius: 5px;
}

.request-btn {
    color: #fff !important;
    float: right !important;
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    // margin-right: 113px;
    font-size: 16px !important;
    width: 141px;
    margin-top: -5px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        animation: colorShift 10000ms infinite ease-in;

    }

    @keyframes colorShift {

        0%,
        100% {
            background: $textcolor;
        }

        33% {
            background: #fb3e3e;
        }

        66% {
            background: #0dcc00;
        }
    }

}

.craousel-image {
    // margin-top: 20%;
    position: inherit;
    // z-index: 9999;
    margin-bottom: 2%;
    width: 282px;
    // width: -webkit-fill-available;
}

.craousel-image2 {
    margin-left: -20%;
    margin-top: 22%;
    max-width: 61%;
}

.navbar-light .navbar-toggler {
    border: none !important;

    &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.text-right-footer {
    text-align: right;
}

input[type="radio"] {
    display: none;
}

.frame {
    width: auto;
    height: auto;
    margin-top: -200px;
    margin-left: -200px;
    border-radius: 2px;
    overflow: hidden;
    color: #333;
    font-family: "Open Sans", Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
}

.center {
    // position: absolute;
    // top: 37%;
    // left: calc(100% - 40%)!important;
    // transform: translate(-50%, -50%);
    // transform: perspective(60px);
    // transform-style: preserve-3d;
}

.block {
    position: absolute;
    top: -153px;
    left: -464px;
    // width: 354px;
    border-radius: 5px;
    // .title {
    //     width: 100%;
    //     height: 16px;
    //     background: #f4d03f;
    // }
    // .content {
    //     padding: 8px 10px;
    //     .line {
    //         width: 80px;
    //         height: 3px;
    //         background: #ebebe9;
    //         margin-bottom: 4px;
    //     }
    //     .space {
    //         width: 80px;
    //         height: 3px;
    //         margin-bottom: 4px;
    //     }
    // }
}

.b1 {
    animation: block1 9s infinite;
}

.b2 {
    animation: block2 9s infinite;
}

.b3 {
    animation: block3 9s infinite;
}

@keyframes block1 {

    0%,
    1%,
    100% {
        transform: translate3d(0px, 0, 15px);
    }

    33.33% {
        transform: translate3d(-105px, 0, -5px);
    }

    66.66% {
        transform: translate3d(105px, 0, -5px);
    }
}

@keyframes block2 {

    0%,
    100% {
        transform: translate3d(-105px, 0, -5px);
    }

    33.33% {
        transform: translate3d(105px, 0, -5px);
    }

    66.66% {
        transform: translate3d(0px, 0, 15px);
    }
}

@keyframes block3 {

    0%,
    100% {
        transform: translate3d(105px, 0, -5px);
    }

    33.33% {
        transform: translate3d(0px, 0, 15px);
    }

    66.66% {
        transform: translate3d(-105px, 0, -5px);
    }
}

@media only screen and (max-width: 767px) {
    .btn-group-tab-admin{
        li{
            width: auto !important;
        }
    }
    .about-3 {
        padding-left: 0px !important;
    }

    .banner-about {
        margin-left: auto !important;
        margin-top: -27px !important;
    }

    .loan-product-box-bg {
        height: 90% !important;
    }

    .bg-blur-circle {
        // background-size: 100% !important;
        height: 50px !important;
        width: 200px !important;

    }

    .bg-blur-blue {
        height: 50px !important;
        width: 200px !important;
    }

    .process-main {
        display: none;

    }

    .virticle-line {
        border: none !important;
    }

    .bank-text {
        text-align: center;
    }

    .menusideborder {
        min-height: auto !important;
        border: none !important;
    }

    .popup-contact-us {
        width: 90% !important;
    }

    // .feature-tagline-heading-text{
    //     font-size: 8px ;
    // }
    // .about-first{
    //   background-size: 95% 100% !important;

    // }
    .about-us-card-bottom {
        padding: 40px !important;
    }

    .footer-link {
        font-size: 17px !important;
    }

    .community-card {
        background-size: 81%, 26% !important;
        padding: 20px !important;
        height: 180px !important;
    }

    // .feature-tagline-heading{
    //     font-size: 25px !important;
    // }
    .mobile-hide {
        display: none !important;
    }

    .community-card {
        background-size: 59%, 26%;
    }

    .mask-about {
        width: auto !important;
    }

    .about-us-card {
        width: 100% !important;
    }

    .about-us-card1 {
        width: 100% !important;
    }

    .font-size-banner {
        font-size: 16px !important;
    }

    .logo-top {
        margin-left: auto !important;
    }

    .head-about {
        margin-top: 30% !important;
        margin-left: auto !important;
    }

    .frame {
        margin-bottom: 66% !important;
        margin-top: 0 !important;
        width: 200px;
        height: 150px;
    }

    .mobile-over-flow {
        overflow: hidden;
    }

    .craousel-image {
        width: 39% !important;
    }

    .block {
        position: absolute;
        top: -65px !important;
        left: -176 !important;
        /* width: 354px; */
        border-radius: 5px;
    }

    .center {
        left: 74%;
        top: 42% !important;
    }

    .home-first {
        background-size: 100% 62%;
    }
}

@media only screen and (width: 1024px) {
    .banner-about {
        margin-top: 183px !important;
    }

    .dropdown-menu-loan-latest::after {
        right: 90% !important;
    }

    .drop-down-main {
        .dropdown-menu {
            right: 60% !important;

        }
    }

    .borrower-content-fontSize {
        font-size: 15px !important;
    }

    .second-box {
        margin-bottom: -8% !important;
    }
}

@media only screen and (width: 768px) {
    .loan-page-first-section {
        height: 636px !important;
    }

    .second-menu-div {

        left: 60% !important;
    }

    .dropdown-menu-new {
        width: 60% !important;
    }

    .margin-left-handle {
        margin-left: 10px !important;
    }

    .skyblue-box {
        height: 70px !important;
        width: 70px !important;
        margin-top: 0px !important;
    }


    .loan-product-box {
        min-height: 280px !important;
    }

    .carousel .dropdown-menu-loan-offering {
        left: -181px !important;
    }

    .dropdown-menu-loan-latest {
        margin-left: 73px !important;
    }

    .dropdown-menu-loan-latest::after {
        right: 82% !important;
    }

    .dropdown-menu-loan-offering::after {
        right: 60% !important;
    }

    .second-box {
        margin-bottom: -11% !important;
    }

    .borrower-content-fontSize {
        font-size: 12px !important;
    }

    .lenders-bg {
        background-size: 75% 50% !important;

        background-position-y: center !important;

        img {
            margin-top: 50% !important;
        }

    }

    .light-red {
        left: 33% !important;
    }

    .loan-page-first-section {
        min-height: 300px !important;
    }

    .first-image-loan {
        margin-top: 40% !important;
        margin-bottom: 50%;
    }

    .loan-banner-content {
        margin-top: 20%;

        h1 {
            font-size: 30px !important;
        }
    }

    .loan-last-circle {
        height: 15px !important;
        width: 15px !important;
    }

    .last-loan-card {
        font-size: 12px !important;
        width: 100% !important;
    }

    .u-shape-box {
        height: 320px !important;
    }

    .first-image-weserve {
        height: auto !important;
    }

    .fintech-second-banner-text-1 {
        width: auto !important;
    }

    .fintech-second-banner-text-2 {
        width: auto !important;
    }

    .bg-image-fintech-3 {
        background-size: 100% 100% !important;
    }

    .onboarding-cards {
        height: auto !important;
    }

    .footer-link {
        font-size: 14px !important;
    }

    .craousel-image {
        width: 150px !important;
    }

    .center {
        top: 60% !important;
        left: 18% !important;
    }

    // .block {
    //     left: auto !important;
    //     width: auto !important;
    // }
    .logo-top {
        margin-left: auto !important;
    }

    .navbar-collapse {
        color: #fff !important;
        background: transparent;
    }
}

.lenders-bg {
    background-image: url("../images/lenders-back.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 78% 68%;
    background-position-y: bottom;
}

.lenders-bg-new {
    // background-image: url("../images/lenders-back.png");
    // background-repeat: no-repeat;
    // background-position: right;
    // background-size: 80% 68%;
    // background-position-y: bottom;
}

.request-btn-lenders {
    margin-top: 20%;
    color: #fff !important;
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 14px !important;
    margin-right: 20px;
    height: 60px;
    width: 200px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
}

.card-lenders {
    height: 202px;
    border-radius: 5px;
    background: #f2f2fa;
    margin-top: 10%;
    padding: 15px;
}

.finance-heading {
    margin-top: 49%;
}

.heading-diversity {
    margin-top: 10%;
    margin-bottom: 20%;
}

.community-bg {
    // overflow: hidden;
    background-image: url("../images/Polygon 1.png"), url("../images/Group 82.png");
    background-repeat: no-repeat;
    background-position: left, right;
    background-size: 21% 100%;
    background-position-y: center;

}

.community-card {
    // background-image: url("../images/communitycard1.png"), url("../images/Group.png");
    // width: 360px;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    background-size: 53%, 26%;
    border-radius: 5px;
    background-position: left bottom, right bottom;
    background-color: #ffffff;
    border-radius: 40px;
    background-repeat: no-repeat;
    // margin-top: 8%;
    padding: 30px 30px;
    margin-top: 50px;
    // margin-bottom: 26%;
    width: 90%;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
}

.lender-margin-left {
    margin-left: 20%;
}

.lender-margin-left-contact {
    margin-left: 20%;
}

.image-width-lenders {
    // margin-left: 24%;
}

.width-community {
    // margin-bottom: 1%;
    width: 89%;
    margin-left: auto;
    margin-right: auto;
}

.about-first {
    // background-position: right;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 39% 273px;
    background-image: url("../images/Vector 4.png");
    background-position-y: top;
    background-size: 97% 88%;
}

.about-image {
    // margin-left: 8%;
    width: 100%;
    margin-top: 20%;
}

.head-about {
    margin-left: 15%;
    margin-top: 22%;
}

.mask-about {
    background-image: url("../images/Frame 7 (2).png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f2f2fa;
    border-radius: 10px;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 37px;
}

.about-us-card {
    background: #ffffff;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 6%;
    margin-left: auto;
    // margin-right: auto;
    width: 97%;
    // margin-top: 30px;
    // margin-bottom: 30px;
    min-height: 302px;

    &:hover {
        background: linear-gradient(63.7deg, #ff6a92 3.15%, #ff5c5d 98.72%);
        box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);

        .feature-tagline-heading {
            color: #fff !important;
        }

        .colorcard-text {
            color: #fff !important;
        }

        .colorcard-text {
            color: #fff !important;
        }
    }
}

.about-us-card1 {
    background: #ffffff;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 6%;
    // margin-left: auto;
    margin-right: auto;
    width: 97%;
    // margin-top: 30px;
    // margin-bottom: 30px;
    min-height: 302px;

    &:hover {
        background: linear-gradient(63.7deg, #ff6a92 3.15%, #ff5c5d 98.72%);
        box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);

        .feature-tagline-heading {
            color: #fff !important;
        }

        .colorcard-text {
            color: #fff !important;
        }
    }
}

.font-size-banner {
    font-size: 17.8px;
    line-height: 1.2;
    margin-top: 18px;
}

.about-text {
    // font-family: Product Sans Light;
    font-style: normal;
    font-weight: 300;
    font-size: 29px;
    color: #7681a1;
}

.colorcard-text {
    font-size: 15px;
}

.req-btn {
    background: #fff !important;
    color: #000 !important;
}

.last-about {
    margin-top: 4%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8%;
    width: 90%;
}

.last-first-image {
    background-image: url("../images/Group\ \(2\).png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}

.about-us-card-bottom {
    background: #ffffff;
    background: #ffffff;
    text-align: center;
    padding: 10px;
    box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    // transform: matrix(1, 0, 0, -1, 0, 0);
    height: 222px;
    margin-top: 25px;
    // width: 92%;
    margin-right: auto;
    margin-left: auto;
}

.circle-bg {
    width: 65px;
    padding: 10px;
    height: 65px;
    background: #eff5ff;
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;
}

.lenders-circle {
    width: 166px;
    height: 166px;
    padding: 9px 10px;

    border-radius: 125px;
    margin-left: auto;
    margin-right: auto;
    // padding: 24px;
    background: #ffffff;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    margin-top: 4%;
}

.lenders-circle-img {
    // margin-left: 15px;
    // margin-top: 24px;
}

.lender-text-bottom {
    color: #182d64;
    margin-top: 13%;
    font-size: 24px !important;
    font-weight: bolder;
}

.ptag-text-lenders {
    margin-top: 10px;
    font-size: 15px;
    color: #7681a2;
}

.banner-btn-req {
    margin-top: 27px;
    padding: 12px 30px;
    font-size: 18px;
    color: $textcolor;
    border-radius: 16px;
    font-style: normal !important;
    font-weight: 500 !important;
    font-family: "product-sans-medium", sans-serif !important;

    &:hover {
        box-shadow: none;
        outline: none;
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.home-tabs {
    color: $textcolor;
    border-radius: 20px;
    font-size: 24px;
    padding: 10px 16px;
    width: 150px;

    &:hover {
        outline: none;
        box-shadow: none;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.black {
    height: 80px !important;
}

.header-margin {
    margin-right: 19px !important;
    font-size: 18px !important;
    font-weight: 600;
    margin-top: -4px !important;
}

.home-new {
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 16px !important;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        border-radius: 16px !important;

    }

    .feature-tagline-heading-text {
        color: $textcolor;
        font-size: 17px;
    }

    @media all and (max-width: 575px) {
        .recharts-wrapper {
            margin-left: -82px !important;
        }

        .last-loan-card {
            width: 100% !important;

            span {
                font-size: 16px !important;
            }
        }

        .feature-tagline-heading-text {
            font-size: 13px !important;
        }

        .font-size-banner-new {
            font-size: 14px !important
        }
    }

    .step-1 {
        margin-left: 14%;
        margin-top: 10%;
    }

    .step-2 {
        margin-left: 12%;
        margin-top: 39%;
    }

    .step-3 {
        margin-left: 12%;
        margin-top: 74%;
    }

    .arrow1 {
        margin-left: 51%;
        margin-top: -10%;
    }

    .arrow2 {
        margin-left: 55%;
        margin-top: -35%;
    }

    // @media all and (min-width: 1406px) and (max-width) {
    //     .center {
    //         left: 80% !important;
    //         top: 10% !important;
    //     }
    //     .block{
    //         top: -187px !important;
    //     }
    // }

    @media all and (min-width: 1422px) {
        .inner-first {
            margin-top: 37.5%;
            margin-left: 19.5%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 40px !important;
                line-height: 1.1 !important;
            }

            h3 {
                margin-top: 30px !important;
            }
        }

        .craousel-image {
            margin-top: 14px;
            width: 325px;
        }

        .logo-top {
            // margin-left: 135px !important;
        }

        .banner-btn-req {
            font-size: 19px;

            &:hover {
                box-shadow: none;
                outline: none;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .font-size-banner {
            font-size: 19px !important;
        }

        .center {
            left: 80% !important;
            top: 44% !important;
        }

        // .block{
        //     top: -187px !important;
        // }
    }



    @media all and (min-width: 1077px) and(max-width: 1200px) {
        .inner-first {
            margin-top: 37.5%;
            margin-left: 19.5%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 30px !important;
                line-height: 1.1 !important;
            }

            h3 {
                margin-top: 30px !important;
            }
        }

        .craousel-image {
            margin-top: 37px;
            width: 236px;
        }

        .logo-top {
            // margin-left: 135px !important;
        }

        .banner-btn-req {
            font-size: 14px;

            &:hover {
                box-shadow: none;
                outline: none;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .font-size-banner {
            font-size: 13px !important;
        }

        .center {
            left: 72% !important;
        }

        .block {
            left: -350px !important;
        }
    }

    @media all and (min-width: 769px) and(max-width: 1077px) {
        .inner-first {
            margin-top: 37.5%;
            margin-left: 19.5%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 30px !important;
                line-height: 1.1 !important;
            }

            h3 {
                margin-top: 30px !important;
            }
        }

        .craousel-image {
            margin-top: 70px;
            width: 200px;
        }

        .logo-top {
            margin-left: 14px !important;
        }

        .banner-btn-req {
            font-size: 14px;

            &:hover {
                box-shadow: none;
                outline: none;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .font-size-banner {
            font-size: 13px !important;
        }

        .center {
            left: 90% !important;
        }

        .block {
            left: -285px !important;
        }

        .step-1,
        .step-2,
        .step-3 {
            width: 80px;
        }

        .arrow1,
        .arrow2 {
            width: 100px;
        }
    }

    @media all and (min-width: 576px) and(max-width: 767px) {
        .doc-image {
            text-align: center !important;
        }

        .ptag-text-html-text {

            p,
            span {

                font-size: 14px !important;
            }
        }

        .ptag-text-html-text-second {

            p,
            span {
                font-size: 14px !important;
            }

            li {
                font-size: 12px !important;
            }
        }

        .con .step-1,
        .step-2,
        .step-3 {
            width: 80px;
        }

        .arrow1,
        .arrow2 {
            width: 100px;
        }

        .home-first {
            background-size: 100% 100% !important;
        }

        .inner-first {
            margin-top: 37.5%;
            margin-left: 19.5%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 15px !important;
                line-height: 1.1 !important;
            }

            h3 {
                font-size: 12px !important;
                margin-top: 10px !important;
            }
        }

        .craousel-image {
            margin-top: 20px !important;
            width: 46% !important;
        }

        .logo-top {
            // margin-left: 135px !important;
        }

        .banner-btn-req {
            font-size: 8px !important;
            padding: auto !important;

            &:hover {
                box-shadow: none;
                outline: none;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .font-size-banner {
            font-size: 13px !important;
        }

        .center {
            left: 95% !important;
        }

        .block {
            left: -350px !important;
        }

        .feature-tagline-heading {
            font-size: 15px !important;
        }

        .heading-diversity {
            margin-top: 30px !important;
        }

        .ptag-text {
            font-size: 10px !important;
        }

        .ptag-text-html-text {
            font-size: 14px !important;
        }

        .ptag-text-html-text-second {

            p,
            span {
                font-size: 12px !important;
            }

            li {
                font-size: 12px !important;
            }
        }
    }

    @media all and (min-width: 769px) and(max-width: 900px) {
        .inner-first {
            margin-top: 37.5%;
            margin-left: 19.5%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 20px !important;
                line-height: 1.1 !important;
            }

            h3 {
                font-size: 14px !important;
                margin-top: 30px !important;
            }
        }

        .craousel-image {
            margin-top: 84px;
            width: 163px;
        }

        .logo-top {
            // margin-left: 135px !important;
        }

        .banner-btn-req {
            font-size: 14px;

            &:hover {
                box-shadow: none;
                outline: none;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .font-size-banner {
            font-size: 8px !important;
        }

        .center {
            left: 85% !important;
        }

        .ptag-text {
            font-size: 10px !important;
        }
    }

    @media screen and (min-width: 320px) and (max-width: 576px) {
        .doc-image {
            text-align: center !important;
        }

        .ptag-text-html-text-second-know {
            p {
                color: #7681a2 !important;
                // margin-top: 5% !important;
                text-align: left;
                font-size: 18px !important;
                // font-weight: 700 !important;
                padding: 0 !important;
            }

            ul,
            span {
                padding: 0 !important;

                color: #7681a2 !important;
                margin-top: 10px !important;
                text-align: left;
                font-size: 14px !important;

                li::marker {
                    color: #267DFF !important;
                    font-size: 24px !important;
                }
            }
        }

        .ptag-text-html-text {

            p,
            span {

                font-size: 14px !important;
            }
        }

        .ptag-text-html-text-second {

            p,
            span {
                font-size: 14px !important;
            }

            li {
                font-size: 12px !important;
            }
        }

        .navbar-nav {
            padding-left: 20px !important;
        }

        .partner-as-lender-content-second {
            font-size: 14px;
        }

        .inner-first {
            margin-top: 37.5%;
            margin-left: 19.5%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 20px !important;
                line-height: 1.1 !important;
            }

            h3 {
                font-size: 15px !important;
                margin-top: 30px !important;
            }

            .craousel-image {
                width: 51% !important;
                margin-top: 37% !important;
            }

            .request-btn {
                margin-right: 44px !important;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &:active {
                    outline: none;
                    box-shadow: none;
                }
            }

            .ptag-text {
                font-size: 10px !important;
            }

            .feature-tagline-heading {
                font-size: 14px !important;
            }

            .community-card {
                background-size: 77%, 26% !important;
                padding: 57px !important;
                height: 235px !important;
            }
        }
    }

    @media only screen and (width: 768px) {
        .wrap-text {
            white-space: break-spaces !important
        }

        .loan-page-first-section {

            min-height: 300px !important;
        }

        .first-image-loan {
            margin-top: 40% !important;
            margin-bottom: 50%;
        }

        .home-tabs {

            width: 119px !important;
        }

        .socialicon {
            font-size: 10px !important;
            width: 20px !important;
        }

        .footer-head {
            font-size: 18px !important;

        }

        .footer-text {
            font-size: 14px !important;
        }

        .footer-req {
            font-size: 14px !important;
        }

        .nav-item {
            a {
                font-size: 12px !important;

            }
        }

        .font-size-banner-new {
            font-size: 14px !important
        }

        .dropdown-menu:after {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #fff;
            // right: 64% !important;
            top: -20px;
        }

        .block {
            top: -112px;
            left: -235px;
        }

        .inner-first {
            margin-top: 37.5%;
            margin-left: 19.5%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 26px !important;
                line-height: 1.1 !important;
            }

            h3 {
                font-size: 15px !important;
                margin-top: 30px !important;
            }

            .craousel-image {
                width: 51% !important;
                margin-top: 37% !important;
            }
        }

        .font-size-banner {
            font-size: 10px !important;
        }

        .request-btn {
            margin-right: 44px !important;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:active {
                outline: none;
                box-shadow: none;
            }
        }

        .finance-heading {
            font-size: 20px !important;
        }

        .heading-diversity {
            margin-top: 40px !important;
        }

        .ptag-text {
            font-size: 10px !important;
        }

        .box-heading {
            font-size: 15px !important;
        }

        .center {
            left: 80% !important;
        }

        .arrow1 {
            width: 70% !important;
        }

        .arrow2 {
            width: 70% !important;
        }

        .step-1,
        .step-2,
        .step-3 {
            width: 100px !important;
        }

    }

    .inner-first {
        margin-top: 45.5%;
        margin-left: 24%;
        margin-bottom: 30%;
    }

    .font-size-banner {
        font-size: 17px;
    }

    .center {
        // position: absolute;
        // top: 37%;
        // left: calc(100% - 40%)!important;
        position: absolute;
        left: 89%;
        top: 50%;

        transform: translate(-50%, -50%);
        transform: perspective(60px);
        transform-style: preserve-3d;
    }

    @media only screen and (max-width: 575px) {


        .navbar-collapse {
            padding-left: 20px !important;

        }

        .navbar-nav {
            padding-left: 20px !important;
        }

        .nav-mobile-size {
            display: block !important;
            text-align: center !important;
            width: 55% !important;
        }

        .inner-first {
            margin-top: 30%;
            margin-left: 0 !important;
            margin-bottom: auto !important;
        }

        .home-first {
            background-size: 600px 100%;
        }

        .craousel-image {
            margin-top: -10%;
            width: 47% !important;
            // margin-left: 24% !important;
        }

        .ptag-text {
            font-size: 14px !important;
        }

        .header-margin {
            margin-top: auto !important;
        }

        .center {
            left: 82%;
        }

        .block {
            left: -310px !important;
        }
    }

    @media only screen and (width: 320px) {

        .block {
            left: -315px !important;
        }

        .center {
            left: 100% !important;
        }

    }

    @media only screen and (width: 414px) {
        .center {
            left: 77% !important;
        }
    }

    @media only screen and (width: 375px) {
        .center {
            left: 86% !important;
        }
    }

    @media only screen and (width: 540px) {
        .center {
            left: 60% !important;
        }
    }

    @media only screen and (width: 425px) {
        .center {
            left: 89% !important;
        }
    }

    @media only screen and (width: 425px) {
        .center {
            left: 76% !important;
        }
    }

    @media only screen and (width: 360px) {
        .center {
            left: 87% !important;
        }
    }

    @media screen and (min-width: 1900px) {
        .center {
            left: 60% !important;
        }

        .inner-first {
            margin-top: 37.5%;
            margin-left: 30%;
            margin-bottom: 30%;

            h1 {
                font-style: normal !important;
                font-weight: 500 !important;
                font-family: "product-sans-medium", sans-serif !important;
                font-size: 70px !important;
                line-height: 1.1 !important;
            }

            h3 {
                font-size: 40px !important;
                margin-top: 30px !important;
            }

            .craousel-image {
                width: 100% !important;
            }
        }

        .font-size-banner {
            font-size: 30px !important;
        }

        .request-btn {
            font-size: 35px !important;
            margin-right: 60px !important;
            width: auto !important;
            border-radius: 10px !important;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:active {
                outline: none;
                box-shadow: none;
            }
        }

        .finance-heading {
            font-size: 35px !important;
        }

        .heading-diversity {
            margin-top: 100px !important;
        }

        .header-fixed {
            height: 113px !important;
        }

        .header-margin {
            margin-right: 19px !important;
            font-size: 30px !important;
            margin-top: -13px !important;
        }
    }
}

.footer-link {
    color: #7681a2 !important;
    font-size: 17px;
    text-decoration: none !important;
    margin-top: 10px;
}

.contact-bg1 {
    background-image: url("../images/Rectangle\ 62\ \(1\).png");
    background-repeat: no-repeat;
    background-position: left 100%;
    background-size: 39%;
}

.contact-bg2 {
    background-image: url("../images/Group\ 5516.png");
    background-repeat: no-repeat;
    background-position: right 64px;
    background-size: 100%;
    height: 841px;
}

.contact-side-text {
    margin-top: 53%;
    font-weight: 700;
    color: #fff;
}

.text-white {
    color: #fff;
}

.contact-box {
    width: 650px;
    // height: 480px;
    left: 150px;
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    z-index: 1;
    position: sticky;
    padding: 23px 20px 16px 20px;
}

@media only screen and (min-width: 2500px) {
    .private-margin {
        margin-top: -8px !important;
    }

    .banner-about {
        margin-top: 200px !important;
    }

    .image-center {
        width: 10% !important;
        text-align: center !important;
    }

    .fa-arrow {
        margin-right: 20px !important;

    }

    .m-top-10 {
        margin-top: 10px !important;
    }

    .m-top-50 {
        margin-top: 50px !important;
    }

    .m-top-30 {
        margin-top: 30px !important;
    }

    .logo-top {
        width: 220px !important;
    }

    .font-size-add {
        font-size: 40px !important;
    }

    .fa-arrow {
        margin-top: 80px !important;
    }

    .min-height-500 {
        min-height: 500px !important;
    }

    .pink-rectangle {
        width: 93px !important;
        height: 93px !important;

    }

    .margin-top-handle-menu {
        margin-top: 83px !important;
    }

    .carousel-height {
        min-height: 450px !important;
    }

    .blue-border {
        border-radius: 33px !important;
    }

    .buiness-heading {
        margin-left: 7% !important;
    }

    .dropdown-menu-first-1 {
        .dropdown-menu {
            width: 1800px !important;
        }
    }

    .drop-down-main {
        .dropdown-menu {
            width: 1300px !important;
        }
    }

    .allignhandle {
        text-align: -webkit-right !important;
    }

    .loan-product-box,
    .loan-product-box-bg {
        height: 92% !important;

    }

    .small-image-business {
        width: 200px !important;
    }

    .padding-hd-ss {
        padding-top: 100px !important;
    }

    .card-tour {
        width: 310px !important;
    }

    .borrower-content-fontSize {
        font-size: 32px !important;
    }

    .feature-tagline-heading-top {
        font-size: 4.5rem !important;
    }

    .image-width-lenders {
        margin-top: 15%;
        width: 88%;
        // margin-left: 10%;

    }

    .community-bg {
        background-size: 17% 100% !important;
    }

    // .nav-item:hover .dropdown-menu {
    //     width: 485px !important;
    //     margin-top: 20px;
    // }

    // .dropdown-menu:after {
    //     right: 70% !important;
    // }
    .dropdown-menu-loan-latest,
    .dropdown-menu-loan-offering {
        margin-top: 25px !important;
        // min-width: 1167px !important;
        // width: 1300px !important;
    }

    .dropdown-menu-first-1 {
        .dropdown-menu:after {
            right: 83% !important;
        }
    }

    .drop-down-main {
        .dropdown-menu::after {
            right: 62% !important
        }

    }

    .market-place-bg {
        background-image: linear-gradient(180deg, #FF6A92 0%, #FF5C5D 100%);
        transform: skew(12deg);
        height: 65%;
        border-top-left-radius: 100px;
        position: relative;
        right: -43%;
        border-bottom-left-radius: 100px;
        top: 25%;

    }

    .card-solutions {
        height: auto !important;

        h6 {
            top: -5px !important
        }
    }

    .website-login-btn {
        width: auto !important;
    }

    .loan-second-box {
        min-height: 500px !important;
    }

    .loan .verification-card {
        height: 220px !important;
    }

    .card-image-loan {
        height: 700px !important;

    }

    .blue-dot {
        height: 15px !important;
        width: 15px !important;
        margin-top: 15px !important;
    }

    .loan-card-third-section {
        margin-bottom: 250px !important;
        height: 500px !important;

        h3 {
            font-size: 40px;

        }

        h6 {
            font-size: 30px !important;
        }
    }

    .request-btn {
        font-size: 34px !important;
        width: 246px !important;
    }

    .loan-banner-content {
        h1 {
            margin-left: 0px;
            font-size: 70px !important;
        }
    }

    .first-image-loan {
        width: 100%;
    }

    .socialicon {
        font-size: 20px !important;
        width: 50px !important;
    }

    .footer-head {
        font-size: 36px !important;

    }

    .footer-text {
        font-size: 30px !important;
    }

    .footer-req {
        font-size: 30px !important;
    }

    .nav-item {
        a {
            font-size: 40px !important;
        }
    }

    .website-login-btn {
        font-size: 35px !important;
        padding: 10px;
    }

    .logo-top {
        // width: 100px !important;
    }

    // .feature-tagline-heading-text{
    .contact-box {
        width: 1211px !important;
        height: 630px !important;
    }

    .second-box {
        margin-bottom: -4%;
    }

    .request-btn-contact {
        margin-top: 30px;
    }

    .contact-bg2 {
        height: 1300px !important;
        margin-top: 37px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1379px) {



    .contact-box {
        width: 510px !important;
        // height: 408px !important;
    }

    .contact-bg2 {
        // background-position: center !important;
        background-size: auto !important;
        height: 693px !important;
    }
}

@media screen and (min-width: 1144px) and (max-width: 1378px) {

    .contact-box {
        width: 600px !important;
        // height: 408px !important;
    }

    .contact-bg2 {
        // background-position: center !important;
        background-size: auto !important;
        height: 750px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
    .contact-box {
        width: 100% !important;
        height: auto !important;
        padding: 20px !important;
    }
}

.request-btn-contact {
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
    color: #fff;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    border-radius: 16px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        outline: none;
        background: #fff;
        border: 1px solid #ff6a92;
        box-shadow: none;
    }
}

@media only screen and (width: 820px) {
    .kyc-image {
        margin-top: 30% !important;
    }

    .first-image-weserve {
        height: 400px !important;
    }

    .contact-box {
        width: 427px;
        // height: 426px;
    }

    .contact-bg2 {
        background-size: auto !important;
        height: 710px !important;
    }
}

@media only screen and (width: 912px) {
    .contact-box {
        width: 427px !important;
        // height: 426px !important;
    }

    .contact-bg2 {
        background-size: auto !important;
        height: 710px !important;
    }
}

@media screen and (min-width: 1163px) and (max-width: 1280px) {
    .contact-box {
        width: 560px !important;
    }

}

//////////////Esign ///////////////

.bg-esign {
    background-image: url("../images/Ellipse 158.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.logo-esign {
    margin-top: 20px;
    width: 150px;
    margin-right: 10px;
}

.text-Heading-Esign {
    color: $textcolor;
    font-weight: 700;
    margin-top: 10px;
}

.text-color {
    color: $textcolor !important;
}

.eSign-text-grey {
    color: #7681a2;
}

.eSign-text-grey-form {
    color: #7681a2;
    margin-bottom: 10px;
    font-size: 16px;
}

.esign-box {
    // width: 90%;
    margin-left: auto;
    /* height: 426px; */
    border-radius: 40px;
    margin-top: 20%;
    background: #ffffff;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    padding: 10px;
}

.upper-image-esig {
    max-width: 217px;
}

.margin-right-left-content {
    margin-right: 55px;
}

.bg-esign-main {
    background-image: url("../images/Group (3).png");
    background-position: right bottom -32%;
    background-repeat: no-repeat;
}

@media all and (max-width: 575px) {

    .card-solutions {
        height: auto !important;

        h6 {
            top: 0px !important;
            font-size: 18px !important
        }
    }

    .our-solution-heading {
        margin-top: 20% !important;
    }

    .navbar-collapse {
        padding-left: 20px !important;

    }

    .main-crousel-kyc {
        margin-top: 20% !important;
    }

    .card-kyc {
        min-width: auto !important
    }

    .kyc-image-center {
        text-align: center !important;
    }

    .kyc-image {
        width: 100% !important;
    }

    .card-startup {
        margin-top: 30px;
        height: auto !important;
    }

    .bg-startup {
        border-bottom-left-radius: 0px !important;

        height: 700px !important;
    }

    .banner-image-startup {
        margin-left: auto !important;
    }

    .second-heading-fintech {
        font-size: 14px !important;
    }

    .main-heading-fintech {
        margin-top: 10px !important;
        font-size: 25px !important;
    }

    .banner-fintech {
        margin-top: 28% !important;
        margin-left: auto !important;
    }

    .u-shape-box {
        margin-left: auto !important;
        width: 100% !important;
        height: 236px !important;
    }

    .first-image-weserve {
        height: auto !important;
    }

    .second-image-fintech {
        margin-top: auto !important;
    }

    .second-image-micro {
        width: 77% !important;
        margin-top: auto !important;

    }

    .fintech-second-banner-text-1 {
        width: 100% !important;
        margin-left: auto !important;
    }

    .fintech-second-banner-text-2 {
        width: 100% !important;
        margin-left: auto !important;

    }

    .bg-image-fintech-3 {
        height: auto !important;

        margin-top: 3% !important;
        background-image: none !important;
        background-color: $textcolor !important;
    }

    .banner-onboarding {
        margin-top: 29% !important;
        margin-left: 8px !important;
    }

    .main-heading-onboarding {
        font-size: 20px !important;
    }

    .second-heading-onboarding {
        font-size: 14px !important;
    }

    .second-image-onboarding {
        margin-left: 4% !important;
    }

    .third-image-onboarding {
        margin-left: auto !important;
        width: 100% !important;
        margin-top: auto !important;
    }

    .margin-left-last-section-onboaring {
        margin-left: auto !important;
    }

    .first-image-onboarding {
        background-size: auto !important;
        height: auto !important;
    }

    .third-heading-text {
        font-size: 18px !important;
        margin-top: 20px !important;
    }

    .third-heading-text-sub {
        font-size: 15px !important;
    }

    .circle-heading {
        height: 20px !important;
        width: 20px !important;
        margin-top: 6px !important;
        border: 5px solid #182d64 !important;
        border-radius: 50% !important;
    }

    .margin-left-last-section-onboaring {
        h3 {
            font-size: 18px !important;
        }
    }

    .second-image-onboarding {
        margin-left: 5% !important;
        margin-top: 10% !important;
    }

    .esign-box {
        width: auto;
    }

    .margin-right-left-content {
        margin-right: auto;
    }

    .logo-esign {
        margin-top: 20px;
        // width: 200px;
        // margin-right: 51px;
    }

    .upper-image-esig {
        width: 110px;
    }

    .bg-esign {
        background-image: none;
    }
}

.loan-image {
    margin-top: 40px;
    margin-left: 40px;
    width: 12%;
}

.orange-div-small {
    margin-top: 8%;
    margin-right: 4%;
    margin-left: 4%;

    height: 40px;
    width: 40px;
    background: #ff5c5d;
    box-shadow: 2px 2px 6px rgba(255, 92, 93, 0.4);
    border-radius: 8px;
    padding: 8px;
}

.border-bottom-g {
    border-bottom: 1px solid #959fba;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.validation-contact {
    margin-bottom: -10px;
    text-align: center;
}

.position-fixed {
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    border-radius: 11px;
    background: #fff;
    margin-bottom: 40px;
}

.alert-popup {
    padding: 15px;
    width: auto;
}

.alert-success {

    border-bottom: 5px solid green;
}

.alert-danger {
    border-bottom: 5px solid red;
}

.background-blur {
    position: fixed;
    background: rgba(34, 31, 20, 0.7);
    z-index: 2;
    top: 64px;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(3px);
}

.popup-contact-us {
    padding: 10px;
    color: $textcolor;
    width: 37%;
    max-width: 100%;
    height: 70%;
    border-radius: 16px;
    margin-right: auto;
    margin-top: 12%;
    margin-left: auto;
    background: #ffffff;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
}

.done-btn {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    border-radius: 5px;
    background: #267dff;

    a {
        color: #fff !important;
    }
}

.enach-box {
    margin-left: auto;
    border-radius: 40px;
    margin-top: 20%;
    padding-bottom: 57px;
    background: #ffffff;
    margin-bottom: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.iframe-doc {
    height: 400px;
    border-radius: 20px;
    margin-top: 20px;
    width: 100%;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
}

.select-esign {
    margin-left: auto;
    width: 85%;
    border-radius: 20px;
    margin-right: auto;
}

.background-blur-esign {
    position: fixed;
    background: rgba(34, 31, 20, 0.7);
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(3px);
    overflow: scroll;
}

.background-blur-menu {
    position: fixed;
    background: rgba(34, 31, 20, 0.7);
    // z-index: 2;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    backdrop-filter: blur(1px);
}

.popup-esign {
    color: #182d64;
    max-width: 100%;
    width: 90%;
    padding: 28px;
    border-radius: 16px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20%;
    margin-left: auto;
    background: #ffffff;
}

.alert-popup-new {
    font-size: 18px;
    color: $textcolor;
}

#toolbar {
    background: #ff6a92 !important;
}

.animate-crm-menu {
    animation: animateCRMMenu 0.5s;
    -webkit-animation: animateCRMMenu 0.5s;
}

.animate-crm-menu-back {
    animation: animateCRMMenuBack 0.5s;
    -webkit-animation: animateCRMMenuBack 0.5s;
}

@keyframes animateCRMMenu {
    from {
        left: -200px;
    }

    to {
        left: 0px;
    }
}

@-webkit-keyframes animateCRMMenu {
    from {
        left: -200px;
    }

    to {
        left: 0px;
    }
}

@keyframes animateCRMMenuBack {
    from {
        left: 0px;
    }

    to {
        left: -200px;
    }
}

@-webkit-keyframes animateCRMMenuBack {
    from {
        left: 0px;
    }

    to {
        left: -200px;
    }
}

.first-image-onboarding {
    background-repeat: no-repeat;
    background-size: 100%;
    // height: 600px;
    // background: linear-gradient(63.7deg, #FF5C5D 3.15%, #267DFF 98.72%);
    background-image: url("../images/Group 5562 (1).png");
}

.second-image-onboarding {
    // margin-bottom: -70px;
    // margin-top: 3%;
    margin-left: 2%;
    width: 100%;
    margin-top: 20%;




    // margin-left: 2%;
    // margin-top: 14%;
    // width: 100%;
}

.banner-onboarding {
    margin-top: 25%;
    margin-left: 22%;
}

.main-heading-onboarding {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
}

.second-heading-onboarding {
    text-align: justify;
    color: #fff;
    font-size: 23px;
}

.third-heading-text {
    margin-top: 22%;
    font-size: 30px;
    color: $textcolor;
}

.circle-heading {
    height: 25px;
    width: 25px;
    border: 5px solid $textcolor;
    border-radius: 50%;
}

.grey-text {
    color: #7681A1 !important;
}

.third-image-onboarding {
    margin-left: -38px;
    margin-top: 21%;
}

.margin-left-last-section-onboaring {
    margin-left: 74px;
}

.onboarding-cards {
    // padding: 9px 22px;

    height: 50px;
    // box-shadow: 15px 1 #ff5c5d0f;
    border-radius: 4px;
    background: #FFFFFF;
    border: 0.1px solid #FF6A92;
    box-shadow: 6px 6px 30px #ff5c5d1f;
    border-radius: 4px;
    margin-top: 20px;

    p {
        z-index: 1;
        color: #000;
        position: relative;
        margin-top: 13px;
        margin-left: 7px;
        font-weight: 700;
    }

    // width: 98%;
    &::before {
        transition: 0.9s;
        content: '';
        width: 0;
        background: linear-gradient(90deg, #FF6A92 0%, #FF5C5D 100%);
        border-radius: 5px;
        height: 50px;
        position: absolute;

        p {
            font-weight: 700;
            z-index: 1;
            color: #000;
            position: relative;
            margin-top: 13px;
            margin-left: 7px;
        }
    }

    &:hover {
        &::before {
            background: linear-gradient(90deg, #FF6A92 0%, #FF5C5D 100%);
            width: 90%;
            color: #fff;
            border-radius: 5px;
        }

        p {
            z-index: 1;
            color: #fff;
            font-weight: 700;
            position: relative;
            margin-top: 15px;
            margin-left: 7px;
            font-weight: 700;

        }
    }

}

.bar-pink {
    height: 5px;
    width: 90px;
    background: pink;
    // margin: 20px 0 25px;
    position: relative;
    border-radius: 30px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -4.7px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #ff6a92;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: animate-dot;
    }
}

.bar-green {
    height: 5px;
    width: 90px;
    background: #cdf1d8;
    // margin: 20px 0 25px;
    position: relative;
    border-radius: 30px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -4.7px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #54cba1;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: animate-dot;
    }
}

.bar-blue {
    height: 5px;
    width: 90px;
    background: #a9c9f8;
    // margin: 20px 0 25px;
    position: relative;
    border-radius: 30px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -4.7px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #267dff;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: animate-dot;
    }
}

.bar-yellow {
    height: 5px;
    width: 90px;
    background: #eecd8b;
    // margin: 20px 0 25px;
    position: relative;
    border-radius: 30px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -4.7px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #ffb828;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: animate-dot;
    }
}

.bar-grey {
    height: 5px;
    width: 90px;
    background: #9da6af;
    // margin: 20px 0 25px;
    position: relative;
    border-radius: 30px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -4.7px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: $textcolor;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: animate-dot;
    }
}

@keyframes animate-dot {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(88px);
    }
}

@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.nav-item {
    position: relative;

    padding: {
        top: 10px;
        bottom: 10px;
        left: 0;
        right: 0;
    }

    a {
        font: {
            size: 17px;
            weight: 400;
        }

        color: #fff;
        text-transform: capitalize;

        padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        margin: {
            // left: 15px;
            // right: 15px;
        }

        &:hover,
        &:focus,
        &.active {
            color: $textcolor;
        }

        i {
            font-size: 10px;
            margin-left: 1px;
        }
    }

    &:last-child {
        a {
            margin-right: 0;
        }
    }

    &:first-child {
        a {
            margin-left: 0;
        }
    }

    &:hover,
    &.active {
        a {
            color: $textcolor;
        }
    }

    .dropdown-menu {
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
        background: #fff;
        position: absolute;
        border: none;
        top: 50px !important;
        left: -225px;
        height: 250px;
        // width: 900px;
        border-radius: 10px;
        z-index: 99;
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;

        padding: {
            // top: 20px;
            left: 5px;
            right: 5px;
            bottom: 20px;
        }

        li {
            padding: {
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }

            a {
                text-transform: capitalize;
                padding: 8px 15px;
                // margin: 0;
                color: #000;

                font: {
                    size: 15.5px;
                    weight: 400;
                }

                &:hover,
                &:focus,
                &.active {
                    color: $textcolor;
                }
            }

            .dropdown-menu {
                left: -250px;
                top: 0;
                opacity: 0;
                visibility: hidden;

                li {
                    a {
                        color: #000;

                        &:hover,
                        &:focus,
                        &.active {
                            color: $textcolor;
                        }
                    }

                    .dropdown-menu {
                        left: 220px;
                        top: 0;
                        opacity: 0;
                        visibility: hidden;

                        li {
                            a {
                                color: #000;

                                &:hover,
                                &:focus,
                                &.active {
                                    color: $textcolor;
                                }
                            }

                            .dropdown-menu {
                                left: -250px;
                                top: 0;
                                opacity: 0;
                                visibility: hidden;

                                li {
                                    a {
                                        color: #000;

                                        &:hover,
                                        &:focus,
                                        &.active {
                                            color: $textcolor;
                                        }
                                    }

                                    .dropdown-menu {
                                        left: -250px;
                                        top: 0;
                                        opacity: 0;
                                        visibility: hidden;

                                        li {
                                            a {
                                                color: #000;

                                                &:hover,
                                                &:focus,
                                                &.active {
                                                    color: $textcolor;
                                                }
                                            }

                                            .dropdown-menu {
                                                left: -250px;
                                                top: 0;
                                                opacity: 0;
                                                visibility: hidden;

                                                li {
                                                    a {
                                                        color: #000;

                                                        &:hover,
                                                        &:focus,
                                                        &.active {
                                                            color: $textcolor;
                                                        }
                                                    }

                                                    .dropdown-menu {
                                                        left: -250px;
                                                        top: 0;
                                                        opacity: 0;
                                                        visibility: hidden;

                                                        li {
                                                            a {
                                                                color: #000;

                                                                &:hover,
                                                                &:focus,
                                                                &.active {
                                                                    color: $textcolor;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &.active {
                                                        a {
                                                            color: $textcolor;
                                                        }
                                                    }

                                                    &:hover {
                                                        .dropdown-menu {
                                                            opacity: 1;
                                                            visibility: visible;
                                                            top: -15px;
                                                        }
                                                    }
                                                }
                                            }

                                            &.active {
                                                a {
                                                    color: $textcolor;
                                                }
                                            }

                                            &:hover {
                                                .dropdown-menu {
                                                    opacity: 1;
                                                    visibility: visible;
                                                    top: -15px;
                                                }
                                            }
                                        }
                                    }

                                    &.active {
                                        a {
                                            color: $textcolor;
                                        }
                                    }

                                    &:hover {
                                        .dropdown-menu {
                                            opacity: 1;
                                            visibility: visible;
                                            top: -15px;
                                        }
                                    }
                                }
                            }

                            &.active {
                                a {
                                    color: $textcolor;
                                }
                            }

                            &:hover {
                                .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    top: -15px;
                                }
                            }
                        }
                    }

                    &.active {
                        a {
                            color: $textcolor;
                        }
                    }

                    &:hover {
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            top: -15px;
                        }
                    }
                }
            }

            &.active {
                a {
                    color: $textcolor;
                }
            }

            &:hover {
                .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    top: -15px;
                }
            }
        }
    }

    &:hover {
        .dropdown-menu {
            opacity: 1;
            visibility: visible;
            top: 100%;
        }
    }
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    // right: 69%;
    top: -20px;
}

.dropdown-menu-loan {
    width: 250px !important;
    right: 90% !important;
    left: -50px !important;
    height: auto !important;
    padding: 21px 47px 26px 0px;


}

.menu-main-dropdown {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        color: #267DFF;
    }

    &:active {
        color: #267DFF !important;
    }

    &:visited {
        color: #267DFF;
    }
}

.drop-sub-menu {
    font-weight: 700 !important;
    color: #000 !important;
    font-size: 15px !important;
    // margin-top: 5px;
    list-style: none !important;
    text-decoration: none !important;


}

.ul-drop-pages {
    margin-top: 10px;
    margin-left: 15px;
    text-decoration: none !important;
    // margin-left: -12px;

}

.menu-main-dropdown-active {
    font-size: 16px;
    font-weight: 700;
    color: #267DFF;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.first-image-weserve {
    background-repeat: no-repeat;
    background-size: 100% 120%;
    height: 600px;
    background-image: url("../images/fintech1.png");
    // animation:fading 2s infinite;
}

// @keyframes fading{
//     0%{opacity:0}50%{opacity:1}100%{opacity:0}
// }
.banner-fintech {
    margin-top: 22%;
    //   margin-left: 22%;
}

.main-heading-fintech {
    color: $textcolor ;
    font-size: 60px;
    font-weight: 700;
}

.second-heading-fintech {
    text-align: justify;
    font-size: 18px;
    color: $textcolor;
}

.secong-bg-fintech {
    background-repeat: no-repeat;
    background-size: 114% 90%;
    height: 600px;
    background-image: url("../images/fintech1.png");
}

.u-shape-box {
    text-align: center;
    width: 87%;
    margin-left: auto;
    /* background-image: linear-gradient(109.5deg, rgba(255, 255, 255, 0.28) 0.78%, rgba(255, 255, 255, 0.04) 98.24%); */
    background: linear-gradient(63.7deg, #FF6A92 3.15%, #FF5C5D 98.72%);
    ;
    border-bottom-left-radius: 250px;
    border-bottom-right-radius: 250px;
    height: 550px;

}

.second-image-fintech {
    margin-top: 25%;
    width: 100%;
}

.second-image-micro {
    margin-top: 13%;
    width: 80%;
}

.bg-image-fintech-3 {
    background-repeat: no-repeat;
    background-size: 100% 100%;

    // height: 600px;
    background-position: center;
    background-image: url("../images/fintech3.png");
}

.fintech-second-banner-text-1 {
    // width: 400px;
    text-align: justify;
    padding: 10px;
    font-size: 15px;
    //  margin-left: 12%;
    color: #fff;
    margin-top: 5%;
    margin-bottom: 3%;
}

.fintech-second-banner-text-2 {
    text-align: justify;
    // width: 400px;
    padding: 10px;
    font-size: 15px;

    color: #fff;
    margin-top: 5%;
    margin-bottom: 3%;
}

.text-second-banner {
    font-size: 14px;
    margin-top: 15px;
}

.headin-cards-weserve {
    font-weight: 700;
    color: $textcolor;
    margin-left: 11%;
    text-align: left;

}

.weserve-card-bottom {
    background: #ffffff;
    background: #ffffff;
    text-align: center;
    padding: 10px;
    box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    // transform: matrix(1, 0, 0, -1, 0, 0);
    // height: 222px;
    margin-top: 25px;
    // width: 92%;
    margin-right: auto;
    margin-left: auto;
}

.circle-bg-weserve {
    margin-top: 15px;
    width: 30px;
    padding: 2px;
    height: 30px;
    background: #eff5ff;
    border-radius: 50px;
    margin-left: 11%;
    margin-bottom: 10px;
}

.circle-bg-weserve-solid {
    width: 26px;
    height: 26px;
    background: #FF6A92;
    border-radius: 50px;
}

.bg-startup {
    border-bottom-left-radius: 350px;
    color: #fff !important;
    // height: 550px;
    left: 0px;
    top: 0px;
    background: linear-gradient(63.11deg, #FF5C5D 3.12%, #267DFF 129.51%);

}

.main-weserve-margin {
    margin-left: 5%;
}

.banner-image-startup {
    margin-top: 25px;
    width: 100%;
    margin-left: 23px
}

.bg-second-startup {
    background-image: url('../images/Group\ 82\ \(1\).png');
    background-size: 200px;
    background-position: top;
    background-repeat: no-repeat;
    background-position-x: right;
    margin-top: 5%;

}

.card-startup {
    height: 100%;
    padding: 10px 10px;
    background: #FFFFFF;
    box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
}

.color-border-blue {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #FFFFFF;
    border: 5px solid #267DFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2);
}

.color-border-pink {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #FFFFFF;
    border: 5px solid #FF6A92;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2);
}

.color-border-green {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #FFFFFF;
    border: 5px solid #54CBA1;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2);
}

.color-border-yellow {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #FFFFFF;
    border: 5px solid #FFCB30;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2);
}

.color-border-green-one-px {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
    background: #FFFFFF;
    border: 1px solid #54CBA1;
    box-shadow: 6px 6px 20px rgba(84, 203, 161, 0.2);
}

.color-border-pink-one-px {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
    background: #FFFFFF;
    border: 1px solid #FF6A92;
    box-shadow: 6px 6px 20px rgba(255, 106, 146, 0.2);
}


.color-border-blue-one-px {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
    background: #FFFFFF;
    border: 1px solid #267DFF;
    box-shadow: 6px 6px 20px rgba(38, 125, 255, 0.2);
}

.loan-icon-image {
    margin-top: 18px;
    width: 37px;


}

.sub-text {
    font-size: 16px !important;
}


.blur-blue-circle {
    background-image: url('../images/Group 5568.png');
}

// .blur-green-circle{
//     background-image: url('../images/Ellipse 176.png');

//     }


.cards-wrapper {
    display: flex;
    justify-content: center;
}

.card img {
    max-width: 100%;
    max-height: 100%;
}

.card {
    margin: 0 0.5em;
    // box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    border: none;
    border-radius: 0;
}

.carousel-inner {
    padding: 1em;
}

.carousel-control-prev,
.carousel-control-next {
    background-color: #e1e1e1;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

@media (min-width: 768px) {

    .download-mobile-image {
        position: absolute;
        bottom: 0;
    }

    .card img {
        height: 11em;
    }
}

.kyc-image {
    width: 74%;
    margin-top: 15%;
}

.kyc-image-center {
    text-align: right;
}

.heading-kyc {
    margin-left: 2%;
    margin-top: 4%;
    margin-bottom: 2%;
}

.main-crousel-kyc {
    padding: 40px 0 40px 0;
    background: #FAFAFA;
    border-radius: 20px;
    margin-top: 5%;
}

.card-kyc {
    background: #FFFFFF !important;
    // box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.1) !important;
    border-radius: 40px !important;
    padding: 20px !important;
    min-width: 400px;
    min-height: 350px;
    max-width: 400px;

}

.card-text-kyc {
    line-height: 2;
    font-size: 16px;
}

.kyc-bottom-border {
    width: 100px;
    height: 4px;
    background: linear-gradient(90.02deg, rgba(255, 92, 93, 0.6) 0.01%, rgba(255, 255, 255, 0) 99.98%);
    border-radius: 4px;
}

.last-heading-kyc {
    text-align: center;
    color: $textcolor;
    margin-top: 5%;
    margin-bottom: 5%;
}

.circle-border {


    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    border: 1px solid rgba(255, 106, 146, 0.2);
}

.circle-kyc {


    border-radius: 50%;
    width: 110px;
    height: 110px;
    background: rgba(255, 106, 146, 0.2);
}


@media only screen and (width: 2560px) {
    .bigscreen{
        min-height: 278px !important;
    }
    .container {
        max-width: 2000px !important;
    }

    h1 {
        font-size: 100px !important;
    }

    .u-shape-box {
        border-bottom-left-radius: 401px !important;
        border-bottom-right-radius: 401px !important;
        height: 889px !important;

    }

    .second-image-micro {
        margin-top: auto !important;
    }

    .bg-image-fintech-3 {
        margin-top: 17% !important;
    }

    p {
        font-size: 30px !important;
    }

    h6 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 40px !important;
    }

    .card-kyc {
        min-width: 594px !important;
    }

    h6 {
        font-size: 20px !important;
    }

    h5 {
        font-size: 30px !important;
    }

    .main-crousel-kyc {
        margin-top: 20% !important;
    }

    .onboarding-cards {
        // padding: 9px 22px;

        height: 125px !important;



        &::before {

            height: 125px !important;
        }
    }

    .grey-text {
        font-size: 20px;
    }

    .ptag-text {
        font-size: 30px !important;
    }

    .card-lenders {
        height: 262px !important;
    }

    .community-card {
        height: 484px;
    }

}

// .circle-image{
//     animation-name: rotateMe;
//     animation-duration: 35s;
//     animation-iteration-count: infinite;
//     animation-timing-function: linear;
// }
.animation-bounce {
    animation: bounce 5s linear infinite;
}

.particle {
    position: absolute;
    border-radius: 50%;
}

@for $i from 1 through 30 {
    @keyframes particle-animation-#{$i} {
        100% {
            transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
        }
    }

    .particle:nth-child(#{$i}) {
        animation: particle-animation-#{$i} 60s infinite;
        $size: random(5)+5+px;
        opacity: random(100)/100;
        height: $size;
        width: $size;
        animation-delay: -$i * .2s;
        transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
        background: hsl(random(360), 70%, 50%);
    }
}

.custom-dropdown-select {
    border: none;
    outline: none;
    margin-top: 5%;
    text-align: center;
    font-weight: bold;
}

.instagram {

    background: -webkit-linear-gradient(#feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.dropdown-menu-notification:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    right: 2px !important;
    top: -20px;

}

.dropdown-menu-notification {
    margin-top: 10px !important;
    // margin-left: 20px !important;
}

.partner-div {
    background: linear-gradient(100.17deg, #FFEFF2 0.02%, #DDECFF 68.60%);
    min-height: 429px;

}

.our-parner-card {
    text-align: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    min-height: 160px;
    margin-top: 10px
}

.partner-as-lender-content {
    color: $textcolor;
    margin-top: 100px;
    margin-left: 20px;

}

.partner-as-lender-content-second {
    color: #4E5C7F;
    font-size: 24px;
    margin-top: 20px;
    margin-left: 20px;

}

.lender-apply {
    margin-left: 20px;
    color: #fff !important;
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-right: 113px;
    font-size: 20px !important;
    width: auto;
    margin-top: 10px;
    text-align: center;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
}

.font-size-banner-new {
    font-size: 26px;
    color: #fff;
}

@media only screen and (max-width: 1399px) {
    .home-new {
        .logo-top {
            // margin-left: auto !important;
        }
    }

    .navbar-nav {
        margin-left: 0px !important;
    }

    .request-btn {
        font-size: 12px !important;
        // width: 100px !important;
        margin-right: auto !important
    }

    .nav-item {

        // margin-top: 10px !important;
        a {
            font-size: 14px !important;
        }

    }

    .website-login-btn {
        font-size: 12px !important;
        // width: 100px !important;
        // margin-right: 50px !important
    }

}

.menu-tems {
    cursor: pointer;

    &:hover {
        color: #267DFF !important;
        font-weight: bold;
    }
}

.nav-link-product {
    text-decoration: none !important;
    color: $textcolor;
    cursor: pointer;

    &:hover {
        color: #267DFF !important;
        font-weight: bold;
    }
}

.footer-new {
    background-color: $textcolor !important;
    color: #fff;
}

.footer-text {
    font-size: 20px;
}

.footer-head {
    font-weight: bolder;
    font-size: 24px !important;
}

.verticleline {
    margin-left: 50px;
    height: 200px;
    border-left: 2px solid #fff;
}

.footer-req {
    border-radius: 0%;
    width: 100%;
    background: #334170 !important;
    color: #fff !important;
}

.socialicon {
    padding: 5px;
    font-size: 13px;
    background: #fff;
    border-radius: 5px;
    color: $textcolor !important;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none !important;
    width: 30px !important;
}

.loan-page-first-section {
    background-color: #FFEFF2;
    // margin-top: 20px;
    padding-bottom: 20px;
}

.loan-banner-content {
    margin-top: 25%;
    margin-left: 10%;

    h1 {
        font-weight: 900 !important;
        font: Product Sans Black !important;
    }

    margin-bottom: 30%;
}

.first-image-loan {
    margin-top: 15%;
    max-width: 76%;
}

.loan-third-section {
    background-color: $textcolor;
}

.loan-card-third-section {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    height: 331px;
    background: #4E5C7F;
    border-radius: 12px;

    img {
        max-width: 150px;
        margin-left: 15px;
        margin-top: 15px;
    }

    h3 {
        color: #fff;
        font-weight: 400 !important;
        margin-top: 10px;
        margin-left: 20px;
    }

    h6 {
        color: #fff;
        font-weight: 400 !important;
        margin-top: 15px;
        margin-left: 20px;
    }

    &:hover {
        img {
            transition: 0.5s;

            max-width: 160px;
        }

        box-shadow: -6px -6px 10px rgba(242, 234, 234, 0.08),
        6px 6px 10px rgba(0, 0, 0, 0.08);

        background: #eeeeee;

        h3 {
            color: $textcolor;
            font-weight: 400 !important;
            margin-top: 10px;
            margin-left: 20px;
        }

        h6 {
            color: $textcolor;
            font-weight: 400 !important;
            margin-top: 15px;
            margin-left: 20px;
        }
    }

}

.lender-fourth-section {
    background: #F2F2FA;
    padding-bottom: 2%;

    h1 {
        font-weight: 900 !important;
    }

    p {
        font-weight: 600;
        font-size: 16px;
        color: $textcolor;
    }

    span {
        margin-top: -10px;
    }

}

.blue-dot {
    width: 12px;
    position: absolute;
    height: 12px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: 8px;
    background: #267DFF;
}

.card-image-loan {
    margin-bottom: 40px;
    min-height: auto;
    padding-top: 20%;
    padding-bottom: 20%;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
}

.light-blue-dot {
    width: 32px;
    position: absolute;
    height: 32px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: 2px;
    background: #DDECFF;
    color: #267DFF;
    padding: 5px 0px 0px 13px;
    font-weight: 900;

}

.loan-fifth-section {
    h3 {
        color: #267DFF;
        margin-left: 50px;
    }

    p {
        color: $textcolor;
        margin-left: 50px;
        font-size: 16px;
        font-weight: 600;

    }
}

.verification-card {
    background: #FFFFFF;
    border: 1.5px solid #FFEFF2;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    width: 100%;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 30px;
    text-align: center;

    p {
        font-size: 18px;
    }

    &:hover {
        box-shadow: 0px 0px 10px #FF5C5D;

        .light-red {
            background-color: #FF5C5D;
            color: #FFEFF2;
        }
    }
}

.light-red {
    position: relative;
    left: 40%;
    bottom: 29px;
    font-size: 20px;
    width: 56px;
    height: 56px;
    background-color: #FFEFF2;
    color: #FF5C5D;
    border-radius: 50%;
    font-weight: 900;
    padding: 12px 18px 0px 19px;

}

.last-image-loan {
    max-width: 90%;
}

.last-loan-card {
    width: 72%;
    font-size: 20px;
    color: $textcolor;
    font-weight: 400;
    padding: 20px;


    &:hover {
        font-weight: 900;
        border-radius: 10px;
        box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.05);

        // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        .loan-last-circle {
            background: #FF5C5D;

        }
    }
}

.loan-last-circle {
    width: 32px;
    position: absolute;
    height: 32px;
    border-radius: 50%;
    margin-right: 20px;
    // margin-top: 2px;
    background: #FFEFF2;


}

.fakeimg {
    height: 200px;
    background: #aaa;
}

.loan-second-box {

    height: auto;
    min-height: 328px;
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    bottom: 123px;
    background: #FFFFFF;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;

    h3 {
        font-weight: 700;
    }
}

.loan-secon-btn {
    position: relative;
    margin-top: 4%;

}

.carousel-indicators {
    margin-top: 20px !important;
}

.card-loan-option {
    padding: 20px;
    margin-bottom: 60px !important;

    &:hover {
        .website-login-btn {
            background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
            color: #fff !important;
            transition: 0.5s;
            width: 200px !important;
        }


    }
}

.carousel-indicators li {

    background-color: #182d64;
}

.carousel-indicators .active {

    background-color: #ff6a92
}

.card-solutions {
    cursor: pointer;
    box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 90%;
    text-align: center;
    padding: 20px;

    img {
        width: 90% !important;
    }

    h6 {
        color: $textcolor;
        font-weight: 900;
        position: relative;
        top: 25px
    }

    &:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.2)
    }
}

.our-solution-heading {
    margin-top: 10%;
}

.div-blue {
    background: #0075FF;
    border: 1px solid rgba(0, 117, 255, 0.24);
    box-shadow: inset 0px 4px 0px rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    color: #fff;
    padding: 8px 16px;
    font-size: 18px;
    top: -46px;
    float: right;
    position: relative;

}

.loan-input {
    margin-top: 10%;
    padding: 0px;
    background: #3a4774;
    border: 1px solid rgba(0, 117, 255, 0.24);
    border-radius: 8px;
    color: #fff;

    input {
        color: #fff;
        width: 100%;
        background: #3a4774;
        border-radius: 10px;
        padding: 13px 4px;
        border: none !important;
        outline: none !important;

        &:visited {
            border: none !important;
            outline: none !important;
        }
    }
}

.MuiSlider-track {
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%) !important;
    //    padding: 10px !important;
    border: none !important;
    color: #FFEFF2 !important;

}

.MuiSlider-thumb {
    // background: linear-gradient(100.17deg, #FFEFF2 0.02%, #DDECFF 68.60%);
    /* Created with https://www.css-gradient.com */
    background: $textcolor !important;
    background: -webkit-linear-gradient(top left, $textcolor, #119833) !important;
    background: -moz-linear-gradient(top left, $textcolor, #116498) !important;
    background: linear-gradient(to bottom right, $textcolor, #116498) !important;
    // background: linear-gradient(100.17deg, #ef7e93 0.02%, #91c2ff 68.6%) !important;

}


.MuiSlider-rail,
.MuiSlider-track {
    height: 10px !important;
    border-radius: 20px !important;
    background-color: #d9dbdf !important;

}

// .recharts-text .recharts-pie-label-text {
//     color: #000 !important;
//     font-size: 18px !important;
// }

// tspan {
//     color: #000 !important;
//     font-size: 18px !important;
// }

.recharts-wrapper-new {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -90px !important;

    tspan {
        font-size: 16px;
        font-weight: 900;
        color: #000 !important;
        background-color: #000 !important;
    }
}

.market-place-bg {
    background-image: linear-gradient(180deg, #FF6A92 0%, #FF5C5D 100%);
    transform: skew(12deg);
    height: 75%;
    border-top-left-radius: 100px;
    position: relative;
    right: -28%;
    border-bottom-left-radius: 100px;
    top: 25%;
    text-align: start;

    img {
        transform: skew(-12deg);
        text-align: start;
        margin-top: -15%;
        margin-left: -15%;


    }

}

.card-marketPlace {
    height: auto;
    border-radius: 5px;
    background: #fff;
    margin-top: 50px;
    padding: 15px;

    &:hover {
        background: linear-gradient(63.7deg, #ff6a92 3.15%, #ff5c5d 98.72%);
        box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);

        .feature-tagline-heading {
            color: #fff !important;
        }
    }
}

.lending-process-circle {
    background: #DDECFF;
    box-shadow: 6px 6px 20px rgba(221, 236, 255, 0.9);
    border-radius: 50px;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;

    &:hover {
        // background: linear-gradient(63.7deg, #ff6a92 3.15%, #ff5c5d 98.72%);
        box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    }

    img {
        margin-top: 30px;

    }

}

.dashboard-first-card {
    // box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    // border-bottom: 5px solid $textcolor;
    box-shadow: 1px 0px 10px #7681a1;
    border-radius: 8px;
    padding: 10px 10px 10px;
    color: #fff;
    font-weight: 900;
    font-size: 14px;
    height: 100px;
    margin-top: 10px;
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    b {
        // margin-left: 45px;
        // right: 25px;
        text-align: right;
        color: $textcolor;
        // bottom: 36px;
        font-size: 25px;
        // position: absolute;
        font-weight: 900;

    }
}

.first-card-dash {
    color: #FF6A92;
    font-weight: 900;
    font-size: 16px;

    // background: linear-gradient(63.7deg, #e19970 3.15%, #f2d5c6 98.72%);
}

.second-card-dash {
    color: #267DFF;
    font-weight: 900;
    font-size: 16px;

    // background: linear-gradient(63.7deg, #59c187 3.15%, #c7e2d3 98.72%);
}

.third-card-dash {
    color: #FFB828;
    font-weight: 900;
    font-size: 16px;

    // background: linear-gradient(63.7deg, #eb6b74 3.15%, #f0b0b6 98.72%);
}

.fourth-card-dash {
    color: #54CBA1;
    font-weight: 900;
    font-size: 16px;

    // background: linear-gradient(63.7deg, #4daaac 3.15%, #8fdfe2 98.72%);
}

.profile-dashboard-card {
    box-shadow: 1px 0px 10px #7681a1;
    border-radius: 8px;
    height: 353px;
    margin-top: 20px;
    padding: 20px;
    background: #fff;
}

// .dashboard-card-two {
//     background: linear-gradient(180deg, #182D64 0%, #4E5C7F 100%);
//     box-shadow: 7px 4px 19px #7681A1;
//     border-top-left-radius: 10px;
//     border-bottom-left-radius: 10px;
//     height: 550px;
//     margin-top: 20px;
//     padding: 20px;

// }

// .dashboard-card-two-image {
//     text-align: center;
//     color: #fff !important;

//     img {
//         margin-top: 10%;
//     }
// }

// .dashboard-card-two-tabs {
//     border: 1px solid #E7E7E7;
//     filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.65));
//     border-radius: 64px;
//     margin-top: 30px;
//     margin-bottom: 40px;
//     width: 90%;
//     margin-left: auto;
//     margin-right: auto;
//     padding: 10px;
//     color: #fff;
//     text-align: center;
// }
.chart-div-dashboard {
    padding: 8px;
    margin-top: 20px;
    box-shadow: 1px 0px 10px #7681a1;
    color: #fff !important;
    border-radius: 8px;
    overflow: auto;
    // background: linear-gradient(180deg, #182D64 0%, #4E5C7F 100%);

    tspan {
        font-size: 10px !important;
    }
}

.main-dash-scroll {
    ::-webkit-scrollbar-thumb {
        background: transparent !important;
        border-radius: 50px !important;
        //   background: #ccc; 
        //   border-radius: 10px;
    }
}


$bg: #f3f8fa;
$white: #fff;
$black: #282936;

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
    transition: $property $duration $ease;
}

* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}



button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;

    &.learn-more {
        width: 12rem;
        height: auto;

        .circle {
            @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
            position: relative;
            display: block;
            margin: 0;
            width: 3rem;
            height: 3rem;
            background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
            border-radius: 1.625rem;

            .icon {
                @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                background: $white;

                &.arrow {
                    @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                    left: 0.625rem;
                    width: 1.125rem;
                    height: 0.125rem;
                    background: none;

                    &::before {
                        position: absolute;
                        content: '';
                        top: -0.25rem;
                        right: 0.0625rem;
                        width: 0.625rem;
                        height: 0.625rem;
                        border-top: 0.125rem solid #fff;
                        border-right: 0.125rem solid #fff;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .button-text {
            @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
            position: absolute;
            top: 4px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0.75rem 0;
            margin: 0 0 0 1.85rem;
            color: $black;
            font-weight: 700;
            line-height: 1.6;
            text-align: center;
            text-transform: uppercase;
            font-size: 10px;
        }
    }

    &:hover {
        .circle {
            width: 100%;

            .icon {
                &.arrow {
                    background: $white;
                    transform: translate(1rem, 0);
                }
            }
        }

        .button-text {
            color: $white
        }
    }
}

.progress-dashboard {
    border-radius: 8px;
    // background: #fbeded;
    padding: 15px 33px 15px;
    margin-top: 20px;
    box-shadow: 1px 0px 10px #7681a1;

    .css-yafthl-MuiSlider-markLabel {

        right: -112px !important;
        top: -12px !important;
    }


}

.leads-box {
    min-height: 415px;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;

    h3 {
        font-weight: 900;
        color: #fff;
    }

    img {
        margin-top: 20px;
        max-width: 130px;
    }

    button {
        margin-top: 20px;
        border: 3px solid #fff;
        border-radius: 25px;
        padding: 10px;
        width: 100px;
        margin-left: auto;
        margin-left: auto;

        i {
            color: #fff;
            font-weight: 900;
        }
    }
}

.leads-box1 {
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);

}

.leads-box2 {
    background: linear-gradient(92.39deg, #59c187 1.2%, #8fdfe2 98.7%);

}

.progress-dashboard-blue {
    border-radius: 5px;
    // background: linear-gradient(92.39deg, #8af16d 1.2%, #d1edd5 98.7%);
    padding: 33px 48px 68px;
    margin-top: 43px;


}

.card-solutions-bank {
    cursor: pointer;
    box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 90%;
    text-align: center;
    padding: 20px;
    min-height: 150px;
    max-height: 150px;
    background: #fff;

    &:hover {
        background: rgb(252, 174, 174);
    }

    img {
        width: 70% !important;
    }
}

.credhub-table-website {
    thead {
        background: #e0e2e4 !important;
        color: #666666 !important;
        font-weight: 900 !important;
    }

    th {
        border: none !important;

    }

    td {
        color: #666666 !important;
        font-weight: 900 !important;


    }

    img {
        max-width: 150px;
    }

    tr {
        border-radius: 10px !important;
    }
}

.card-bank-web {
    margin-top: 20px;
    // background:#f7f8fa !important;
    color: #666666 !important;
    font-weight: 900 !important;
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    padding: 20px 10px 20px;
    border-radius: 5px;

    margin-right: auto;
    margin-left: auto;
    width: 90%;

    img {
        max-width: 65%;
    }

    .bank-text {
        margin-top: 10px;
        font-weight: 900;
    }

    .virticle-line {
        border-right: 1px solid #e6e2e2;

    }

    .text-bank-other {
        font-weight: 900;
        text-align: center;
        margin-top: 5px;
        font-size: 15px;
    }

    button {
        color: #fff !important;
        background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
        box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
        margin-left: auto;
        margin-right: auto;
        font-size: 16px !important;
        width: 141px;
        margin-top: 20px;
    }
}

.image-width-borrower {
    max-width: 75%;
    margin-top: 15%;
    position: relative;
    right: -12px;
}

.borrower-content-fontSize {
    font-size: 20px;
}

.borrower-feature {
    padding: 20px;
}

.feature-box {
    width: 80px;
    height: 80px;
    background: #E9ECF5;
    border-radius: 10px;
    text-align: center;
    margin-right: 15px;
}

.feature-loan-card {
    background: #FFEFF2;
    height: 95%;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 25px;

    &:hover {
        background: linear-gradient(63.7deg, #ff6a92 3.15%, #ff5c5d 98.72%);
        box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);

        .ptag-text {
            color: #fff !important;
        }

        .text-color {
            color: #fff !important;

        }
    }

}

.feature-image-box {
    height: 200px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;

    img {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: auto;
    }
}

.borrower-process-circle {
    margin-top: 20px;
    width: 120px;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(255, 92, 93, 0.3);
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to bottom, #FF6A92, #6278E1) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
    margin-left: auto;
    margin-right: auto;


}

.arrow-image {
    width: 30px;
}

.process-div {
    margin-left: 6%;
}

.ul-list-borrower {
    li {
        width: calc(100%/7);
        text-align: center;
    }
}

.Process-image {
    width: 40px;
    margin-top: 30px;

}

.rotate-image {
    transform: rotate(90deg);
}

.Process-image-mobile {
    width: 50px;
    margin-top: 26px;
    margin-left: 24px;

}

.feature-card {
    // padding: 9px 22px;

    height: 50px;
    // box-shadow: 15px 1 #ff5c5d0f;
    border-radius: 4px;
    background: #FFFFFF;
    border: 0.1px solid #FF6A92;
    box-shadow: 6px 6px 30px #ff5c5d1f;
    border-radius: 4px;
    margin-top: 20px;

    p {
        z-index: 1;
        color: #000;
        position: relative;
        margin-top: 13px;
        margin-left: 7px;
        font-weight: 700;
    }
}

.two span {
    // color: #a5cb21;
    opacity: 0;
    transform: translate(200px, -100px) scale(2);
    animation: ballDrop 1s forwards;
}

@keyframes ballDrop {
    60% {
        transform: translate(0, 20px) rotate(-180deg) scale(.5);
    }

    100% {
        transform: translate(0) rotate(0deg) scale(1);
        opacity: 1;
    }
}




.animate span {
    display: inline-block;
}

.list-div {
    border: 1px solid gray;
    padding: 10px;
    border-radius: 10px;
    margin-top: 1px;
}

.qr-code {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 5px;
    margin-bottom: 20px;
}

.app-button {
    width: 50%;
    margin-top: 60%
}

.home-page-crousel {
    img {
        width: auto !important;
        // height: auto !important;
    }

    .owl-dots {
        margin-bottom: 30px !important;
    }
}

.bank-cards {
    border: 0.5px solid rgba(227, 235, 253, 0.48);
    background: #fff;
    position: relative;
    padding: 10px;
    border-radius: 5px;
    // margin-top: 5px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

}

.newCard {
    // transition: all 0.5s ease;
    // will-change: transform;
    animation-name: slide;

}


.last-section-dashboard {
    box-shadow: 1px 0px 10px #7681a1;
    margin-top: 20px;
    border-radius: 5px;
    height: 91.5%;
    padding: 10px;
}

.add-btn-lead {
    color: #fff !important;
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-size: 16px !important;
    width: 141px;
    margin-top: 8px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        animation: colorShift 10000ms infinite ease-in;

    }

    @keyframes colorShift {

        0%,
        100% {
            background: $textcolor;
        }

        33% {
            background: #fb3e3e;
        }

        66% {
            background: #0dcc00;
        }
    }
}

.text-handle {
    text-align: left;
}

.activity-circle {
    margin-top: 20px;
    width: 30px;
    height: 30px;
    background: $textcolor;
    color: #fff;
    // box-shadow: 6px 6px 20px rgba(255, 92, 93, 0.3);
    // background: linear-gradient(white, white) padding-box,
    //     linear-gradient(to bottom, #FF6A92, #6278E1) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
    margin-left: auto;
    margin-right: auto;
}

.bankpage-heading {
    font-weight: 900;
    margin-top: 100px;
    color: $textcolor;
}

.loan-page-second-section {
    min-height: 400px;
}

.slectsearch {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    background: #0c3953;
    height: 100%;
    color: #fff;
    outline: none !important;
    border-right: none !important;
    padding: 10px;

    option {
        font-size: 14px;
    }

    &:active {
        outline: none !important;
    }
}

.slectinput {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background: #fff;
    height: 100%;
    padding: 10px;
    color: #000;
    outline: none !important;
    border-left: none !important;

    option {
        font-size: 14px;
    }

    &:active {
        outline: none !important;
    }

    border: 1px solid gray;

}

.bankImage {
    width: 177px;
    margin-top: 100px;
    height: auto;
    right: 7%;
    position: absolute;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    padding: 10px;
    text-align: center;

    img {
        width: 90%;
    }
}

.hover-grey {
    margin-top: 4px;
    // margin-bottom: 2px;
    border-radius: 10px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: #F2F2FA;
        cursor: pointer;
    }
}

.sidenav-productivity {
    background-color: #eceeef;
    border-right: 1px solid blue;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
    overflow: auto;
}

.productivity {
    ::-webkit-scrollbar-track {
        // background: lightgray !important;
        position: fixed !important;
        margin-left: 210px !important;
    }

    .road-map-table {

        th,
        td {
            border: 1px solid #DFE1E6;
            padding: 20px;
        }

        th {
            background-color: #f4f5f7;
            padding: 10px;

            :first-child {
                width: 300px
            }
        }

        .first-column {
            position: sticky;
            white-space: nowrap;
            left: 0;
            z-index: 9998;
            background: white;
        }

    }
}

.bank-page-cards {
    background: #FFFFFF;
    // border: 1px solid #000000;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    border-radius: 8px;
    // padding: 37px 16px;
    padding: 10px;
    // min-height: 100px;
    margin-top: 10px;
    // cursor: pointer;
    min-height: 92px;
    //    height: 50px;

}

.main-html-class {
    .ptag-text-html-text-second {
        img {
            width: 32px !important;
            height: 32px !important;
        }

        p {
            color: #000 !important;
            // margin-top: 5% !important;
            text-align: left;
            font-size: 24px;
            // font-weight: 700 !important;

        }

        ul,
        span {
            color: #000 !important;
            margin-top: 10px !important;
            text-align: left;
            font-size: 18px !important;
        }
    }

    .ptag-text-html-text-second-know {
        img {
            width: 32px !important;
            height: 32px !important;
        }

        p {
            color: #7681a2 !important;
            // margin-top: 5% !important;
            text-align: left;
            font-size: 24px;
            // font-weight: 700 !important;
            padding: 0 !important;
        }

        ul,
        span {

            color: #7681a2 !important;
            margin-top: 10px !important;
            text-align: left;
            font-size: 18px !important;

            li::marker {
                color: #267DFF !important;
                font-size: 24px !important;
            }
        }

        h2 {
            strong {
                color: $textcolor !important;
                text-align: left !important;
            }
        }

        h3 {
            strong {
                color: $textcolor !important;
            }
        }
    }
}

.knowMore-calc {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #182D64;
    box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;

    .white-color {
        color: #fff !important;
    }

    h3 {
        font-weight: 700;
    }

    .text-color {
        color: #fff !important;
    }

    .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        color: #fff !important;
    }

    .MuiSlider-track {
        // background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%) !important;
        //    padding: 10px !important;
        background: #267DFF !important;
        box-shadow: inset -9px 7px 2px rgba(255, 255, 255, 0.25) !important;
        border: none !important;
        color: #FFEFF2 !important;
    }

    .MuiSlider-thumb {
        box-shadow: inset -9px 7px 2px rgba(255, 255, 255, 0.25) !important;
        background: #fff !important;
    }

}

.wrap-text {
    white-space: nowrap;

}

.light-blue-dot-know-more {
    background: #e0ebfd;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    margin-right: 10px;
    font-weight: 900;
    color: #267DFF;
    padding: 9px 22px;
    font-size: 20px;

}

.doc-image {
    text-align: left;
}

.accordion-main {
    .accordion-item {
        &:last-of-type {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

    }

    .accordion-button {
        //   background: #f2f2fa !important;
        // color: #000 !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        padding: 7px !important;
        outline: none !important;

        border: none !important;
        cursor: pointer !important;
        // border-radius: 5px !important;
        box-shadow: none !important;
        border-radius: none !important;

        &:active,
        &:visited {
            // background: $deepBlue !important ;
            outline: none !important;
            box-shadow: none !important;
            color: #fff !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("../images/ic_round-remove-circle-outline (1).png") !important;


    }

    .accordion-button:after {
        background-image: url("../images/Vector (3).png") !important;
    }


    .accordion-item {
        outline: none !important;
        border: none !important;
        background-color: transparent;
        border-bottom: 2px solid grey !important;
        margin-top: 10px;
        border-radius: none !important;

    }

    .accordion-header {
        border-radius: 5px !important;
        outline: none !important;
        border: none !important;
        border-radius: none !important;

    }

    .accordion-button:not(.collapsed) {
        background: #fff !important;
        color: #000 !important;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }

}

.bankDetailPage {
    background: rgb(242, 242, 250);
    color: $textcolor !important;
    // box-shadow: 0px 0px 24px rgba(0, 0, 24px, 0.1);
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.22));
    // box-shadow: -6px -6px 10px rgba(0 0 0 / 8%), 6px 6px 10px rgba(0 0 0 / 8%);


}

.fancy {
    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background-image:
            url("../images/download 1 (2).svg");
        background-size: 110% auto;
        background-position: center;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 900 !important;
    }
}

.business-color-div {
    background: linear-gradient(90deg, rgba(221, 236, 255, 0.8) 0%, rgba(255, 239, 242, 0.8) 100%);
}

.feature-small-img {
    // width: 80px;
    position: absolute;

    img {
        width: 80%;
    }

}

.product-feature-section {
    h4 {
        color: $textcolor;
        margin-left: 2%;
        font-weight: 900 !important;
    }

    p {
        color: #4E5C7F;
        margin-left: 2%;
        font-size: 16px;
        font-weight: 600;

    }
}

.third-section-business {
    background: #182D64;
    padding-bottom: 20px;
    padding-top: 20px;
}

.loan-product-box {
    margin-top: 30px !important;
    padding: 20px;
    margin-bottom: 60px !important;

    img {
        width: 60%;
        height: auto !important;
    }
}

.view-more-btn {
    color: #000;
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 2px solid #ff6a92;
    bottom: 18px;
    position: absolute;
    // width: 100px;
    margin-left: -52px;
    outline: none !important;

    &:hover {

        background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
        color: #fff !important;
        transition: 0.5s;


    }

    &:active {
        outline: none !important;

    }

}

.loan-product-box-bg {
    margin-top: 30px !important;
    padding: 20px;
    margin-bottom: 60px !important;
    background: #fff;
    height: 70%;

    p,
    h5,
    .view-more-btn {
        color: $textcolor
    }
}

.new-drop-menu {
    background: #fff;
    height: 100% !important;
    z-index: 999999 !important;
    width: 49% !important;
    top: 0 !important;
    left: 0 !important;
    position: absolute !important;
    display: none;
}

.drop-sub-menu-new {
    overflow: scroll !important;
    position: fixed !important;
    width: 40% !important;
    height: 100% !important;
    left: 0px !important;
    width: 525px !important;
    margin-top: 20px;
    border-radius: 0 !important;
    // -webkit-animation: slideIn 2s forwards !important;
    // -moz-animation: slideIn 2s forwards !important;
    // animation: slideIn 2s forwards !important;
    // animation: square-in-bottom-right !important;


}

@-webkit-keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
}

@-moz-keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
}

.slideInClass {
    -webkit-animation: slideIn 0.9s forwards !important;
    -moz-animation: slideIn 0.9s forwards !important;
    animation: slideIn 0.9s forwards !important;
}

@keyframes slideInUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideout {
    from {
        margin-left: 0;
    }

    to {
        margin-left: -50vw;
    }
}

.slideOutClass {
    animation: slideout 3s backward s !important;

}

.dropdown-menu-loan-latest,
.dropdown-menu-loan-offering {
    height: auto !important;
}

@media screen and (min-width: 769px) and (max-width: 1399px) {

    .dropdown-menu-loan-latest,
    .dropdown-menu-loan-offering {
        margin-left: 200px !important;
    }

    // .dropdown-menu-loan-latest:after {
    //     right: 69% !important;
    // }
    .dropdown-menu-first-1 {
        .dropdown-menu-loan-offering::after {
            right: 73% !important;
        }
    }

    .drop-down-main {
        .dropdown-menu::after {
            right: 59% !important;
        }
    }
}

.pink-rectangle {
    width: 56px;
    height: 56px;
    padding: 12px;
    background: #FF6A92;
    margin: 20px;
    border-radius: 16px;
}

.pink-rectangle-small {
    width: 40px;
    height: 40px;
    padding: 6px;
    background: #FF6A92;
    margin-top: 10px;
    border-radius: 10px;
    margin-left: 20px;
}

.menu-box {
    // letter-spacing: 2px;
    cursor: pointer !important;
    // height: 21% !important;
    // padding-top: 15px;
    // margin-top: 15px;


    &:hover,
    &:active {
        background: #ffffff;
        border-radius: 5px;

        // text-decoration: underline;
        p {
            color: grey !important;
        }
    }

    h5 {
        cursor: pointer !important;

    }
}

.menu-box-active {
    // letter-spacing: 2px;
    background: #ffffff;
    border-radius: 5px;

    // margin-top: 15px;
    p {
        color: grey !important;
    }

}

.fa-arrow {
    // text-align: end;
    // margin-top: -54px;
    color: #FF5C5D;
    margin-top: 40px;

}

.accordicon-menu {
    .accordion-body {
        padding-bottom: 0px !important;

    }

    .accordion-item {
        &:last-of-type {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

    }

    .accordion-button {
        //   background: #f2f2fa !important;
        // color: #000 !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        padding: 0px !important;
        outline: none !important;

        border: none !important;
        cursor: pointer !important;
        // border-radius: 5px !important;
        box-shadow: none !important;
        border-radius: none !important;

        &:active,
        &:visited {
            // background: $deepBlue !important ;
            outline: none !important;
            box-shadow: none !important;
            color: #fff !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("../images/Vector (15).png") !important;
        margin-right: 22px;
        background-size: 11px;
        margin-top: -6px;

    }

    .accordion-button:after {
        background-image: url("../images/Vector (14).png") !important;
        margin-right: 22px;
        background-size: 11px;
        margin-top: 6px;
    }


    .accordion-item {
        outline: none !important;
        border: none !important;
        background-color: transparent;
        // border-bottom: 2px solid grey !important;
        margin-top: 10px;
        border-radius: none !important;

    }

    .accordion-header {
        border-radius: 5px !important;
        outline: none !important;
        border: none !important;
        border-radius: none !important;

    }

    .accordion-button:not(.collapsed) {
        background: #fff !important;
        color: #000 !important;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }



}

.link-css {
    h5 {
        margin-top: 30px !important;
        font-weight: 900 !important;

    }

    a {
        text-decoration: none !important;
        color: $textcolor;
        cursor: pointer;
        font-weight: 900 !important;
        font-size: 20px;
        margin-top: 10px !important;


        &:hover {
            color: #267DFF !important;
            font-weight: bold;
        }
    }
}

.drop-down-main {
    .dropdown-menu-loan-offering {
        // margin-left: -140px !important;

        left: -380px;

        .dropdown-menu-loan-offering::after {
            right: 50% !important;
        }

    }

    .dropdown-menu::after {
        right: 20%;
    }

    .dropdown-menu {
        width: 600px;
    }
}

.dropdown-menu-first-1 {
    .dropdown-menu::after {
        right: 69%;
    }

    .dropdown-menu {
        width: 900px;
    }
}

.bg-blur-circle {
    // background-image: url('../images/Ellipse 176 (1).png');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: 28%;
    background: rgba(255, 92, 93, 0.52);
    filter: blur(125px);
    width: 200px;
    height: 200px;
    /* margin-left: 20px; */
    // margin-top: 7%;
    margin-left: 37%;
    position: absolute;
}

.bg-blur-blue {

    width: 200px;
    height: 200px;
    /* margin-left: 20px; */
    margin-top: 7%;
    margin-left: 28%;
    position: absolute;

    background: rgba(38, 125, 255, 0.52);
    filter: blur(125px);

}

.buisnessimage-card {
    margin-right: -12px !important;
    overflow: hidden;


}

.buiness-heading {
    margin-top: 32%;
    margin-left: 10%;
    font-weight: 900
}

@media screen and (max-width: 575px) {
    .buiness-heading {
        margin-left: 0% !important;
    }
}

.blue-border {
    border: 3px solid $textcolor;
    border-radius: 15px;
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);

}

// .image-color-bg{
//     min-height: 400px;
//     // width: 300px;
//     margin-top: 15%;
//     background: linear-gradient(180deg, #FF6A92 0.05%, #FF5C5D 100.05%);
//     // clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 43% 82%);
//     border-bottom-left-radius: 90% !important;
//     // position: absolute;
//     margin-right: -12px;
//     border-radius: 27% 38% 56% 40% / 44% 0% 0% 0% !important;


// }
.image-color-bg {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    //   width: 150px;
    min-height: 500px;
    border: none;
    -webkit-border-radius: 25px 0 0 91px;
    border-radius: 25px 0 0 91px;
    font: normal 100%/normal Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 1);
    -o-text-overflow: clip;
    text-overflow: clip;
    background: linear-gradient(180deg, #FF6A92 0.05%, #FF5C5D 100.05%);
    margin-top: 15%;

}

.industry-box {
    background: #182D64;
    min-height: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    // width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
}

.skyblue-box {
    // width: 135px;
    // height: 135px;
    background: #DDECFF;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;

    img {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 20px;
    }
}

.carousel-height {
    min-height: 350px;
}

.arrow-image-onboarding {

    transform: rotate(90deg)
}

.Process-image1 {
    width: 40px;
    margin-top: 20px;
    margin-left: 25px;

}

.borrower-process-circle-new {
    margin-top: 20px;
    width: 100px;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(255, 92, 93, 0.3);
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to bottom, #FF6A92, #6278E1) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
    margin-left: auto;
    margin-right: auto;


}

.link-tab {
    text-decoration: none;

}

.dropdown-menu-new {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 40%;
    height: 100%;
    background: #fff;
    // background: #f3f0f0;
    position: fixed;

    top: 0;
    left: 0;
    z-index: 99999;


}

.second-menu-div {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 30%;
    height: 100%;
    background: #fff;
    // background: #fff7f7;
    position: fixed;
    padding: 30px 17px 0px;
    top: 0;
    left: 40%;
    z-index: 99999;
    border-left: 1px solid grey;

    .accordicon-menu .accordion-button {
        background: transparent !important;
    }

    p {
        text-align: justify !important;
    }
}

.bg-blur-blue-small {
    width: 100px;
    height: 100px;
    /* margin-left: 20px; */
    margin-top: -5%;
    margin-left: -8%;
    position: absolute;
    background: rgba(38, 125, 255, 0.52);
    filter: blur(45px);

}

.loan-mobility {

    img {
        width: 60%;

    }

    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08),
    6px 6px 10px rgba(0, 0, 0, 0.08);
    height: 95%;
    background-color: #FFEFF2;
    border-radius: 10px;
    text-align: center;


}

.view-more-btn-MOBILITY {
    color: #000;
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 2px solid #ff6a92;

    outline: none !important;

    position: relative;

    margin-left: auto;
    margin-right: auto;
    margin-top: 20px !important;

    &:hover {

        background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
        color: #fff !important;
        transition: 0.5s;


    }

    &:active {
        outline: none !important;

    }
}

.small-industry-card {
    // background: #FFFFFF;
    box-shadow: 6px 4px 60px #E9ECF5;
    border-radius: 20px;
    // height: 200px;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../images/Frame 5705.png');
    background-position: top;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 84%;

    h5 {
        font-weight: 900;
        margin-left: 13px;
        position: relative;
        margin-top: 50px;

    }
}

.yellow-bg {
    background-image: url('../images/Frame 5705 (1).png');

}

.grey-bg {
    background-image: url('../images/Frame 5705 (2).png');

}

.trans-bg {
    background-image: url('../images/trans.png');

}

.green-bg {
    background-image: url('../images/Frame 5705 (4).png');
}

.margin-menu-handle {
    margin-top: 25px;
}

.hover-color {
    padding-left: 5px;
    padding-right: 5px;

    &:hover {
        background-color: #fff;


        color: #000 !important;
        border-radius: 5px;
        cursor: pointer;
    }
}

.letter-spacing-2 {
    // letter-spacing: 2px;
}

.apply-now-btn {
    color: #fff !important;
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    font-size: 20px !important;
    width: 200px;
    margin-top: 50px;
    text-align: center;
    padding: 10px;


}

.type-loc {
    background: #FFFFFF;
    border: 1px solid #E5ECFD;
    box-shadow: 4px 4px 40px #E5ECFD;
    border-radius: 20px;
    // width: 230px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.skyblue-box-letter {
    // height: 200px !important;
    width: 50% !important;
    margin-top: 0px !important;
    background: #DDECFF;
    margin-left: auto;
    margin-right: auto;
    // padding: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    img {
        width: 50% !important;
        // margin-top: 20px;
    }
}

.about-1 {
    background: linear-gradient(180deg, #EEF4FC 0%, #FDF4F7 100%);
    padding: 13% 10%;
    margin-top: 100px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.green-line {
    border-bottom: 1px solid #49D49D;
    width: 150px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.banner-about {
    margin-top: 160px;
    margin-left: -50px;
}

.about-2 {
    padding-top: 70px;
    padding-bottom: 70px;
    background: linear-gradient(269.4deg, #DEECFF 0.49%, #FFFFFF 97.41%);
}

.about-3 {
    padding-top: 70px;
    padding-bottom: 70px;
    background: linear-gradient(180deg, #FFFFFF 0%, #FCF5F9 100%);
    padding-left: 75px;
}

.about-marker {

    li::marker {
        color: $textcolor !important;
        font-size: 30px !important;
    }
}

.sky-blue-circle {
    background: #5789FF;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    opacity: 0.5;
    margin-left: -100px;
    position: absolute;
}

.pink-small-circle {
    background: #FF688A;

    height: 76px;
    width: 46px;
    border-radius: 50px 0px 0px 50px;
    right: 0;
    position: absolute;
    margin-top: -70px;
    // right: 0;
}

.gradient-line {
    background: linear-gradient(90deg, #FC5C7D 0%, #5C6EEA 102.12%);
    border-radius: 20px;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    height: 7px;
}

.merchant-card {
    background: #FFFFFF;
    box-shadow: -6px 6px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;

}

.gradient-bg {
    background: linear-gradient(180deg, #DDECFF 0%, #FFEFF2 100%);
    padding: 16px;
    width: 240px;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    margin-top: 60px;
}

.small-card-merchant {
    background: #FFFFFF;
    box-shadow: -6px 6px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;

    // padding-top: 50px;
    // margin-top: 50px;
    img {
        margin-top: 25px;
    }
}

.gradient-bg-smal {
    background: #fff;
    padding: 16px;
    width: 86px;
    height: 154px;
    margin-left: auto;
    margin-right: auto;

    border-radius: 25px 0px 0px 25px;

}

.gradient-bg-small-left {
    background: #fff;
    padding: 16px;
    width: 86px;
    height: 154px;
    margin-left: auto;
    margin-right: auto;

    border-radius: 0px 25px 25px 0px;
}

.card-lenders-new {
    background: #FFFFFF;
    border: 2px solid #E9ECF5;
    box-shadow: 4px 4px 60px #E5ECFD;
    border-radius: 20px;
    min-height: 450px;
    padding: 10px;
    height: 100%;
    padding-top: 20px;

    img {
        width: 50%;

    }
}

.grey-img {
    background: #f2f2f9;
    padding: 8px;
    border-radius: 15px;
    height: 80px;
    width: 80px;

    img {
        width: 60px;
        // margin-top: 5px;
    }
}

.add-query {
    background: linear-gradient(92.39deg, #ff6a92 1.2%, #ff5c5d 98.7%);
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-size: 16px !important;
    width: 141px;
    color: #fff !important;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        animation: colorShift 10000ms infinite ease-in;

    }
}

.new-apply-btn {
    background: linear-gradient(118.49deg, #FF6A92 16.02%, #FF5C5D 90.75%);
    border-radius: 12px;
    color: #fff;
    width: 150px ;
    padding: 10px;
    margin-top: 20px;
    
}
.new-cancle-btn {
    background: #FFFFFF;
    border: 1px solid #FF5C5D;
    width: 150px ;
    padding: 10px;
    border-radius: 12px;
    margin-top: 20px;
    color: #FF5C5D;
    
}
.main-round{
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
        border-radius: 12px !important;
    }
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        border-radius: 12px !important;
    }
}
