body {
    overflow-x: hidden;
}

hr {
    border: solid 0.5px gainsboro;
    width: 96%;
    color: #FFFF00;
    height: 0.5px;

}

@media(max-width:456px) {
    body {
        font-size: 80%;
    }
}

@media(max-width: 759px) {
    #sidebar {
        display: none;
    }

    .vr {
        display: none;
    }

    .card-heading {
        font-size: 80%;
    }
}


.pill:hover {
    background-color: #E9ECF5;
}



.grid-divider {
    overflow-x: hidden;
    position: relative;
}

.grid-divider>[class*="col-"]:nth-child(n + 2):after {
    content: "";
    background-color: rgb(52, 52, 52);
    position: absolute;
    top: 0;
    bottom: 0;

    @media (max-width: 767px) {
        width: 100%;
        height: 1px;
        left: 0;
        top: -4px;
    }

    @media (min-width: 768px) {
        width: 1px;
        height: auto;
        left: -1px;
    }
}


.sidepanel1 {
    min-height: 100vh;
    background-color: #232F63;
    padding-top: 30px;
}

.navbar1 {

    margin: auto;
}

.button1 {
    color: #FF5C5D;
    border-color: #FF5C5D;
    background-color: white;
}

.button2 {
    color: white;
    background-color: #FF5C5D;
    border-color: #FF5C5D;
}

.lead1 {
    width: 100%;
    margin: auto;
    color: #959FBA80;
}

.lead2 {
    min-height: 100px;
    border-radius: 8px
}

.lead3 {
    color: #267DFF;
    padding-left: 15px;
    padding-top: 10px;
}

.lead4 {
    min-width: 230px;
    padding-bottom: 10px;
}

.recentactivity1 {
    margin-right: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.recentactivity2 {
    margin-right: auto;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.recentactivity3 {
    background-color: #DBF5EC;
    border-radius: 25px;
    padding: 5px 15px;
    margin: 0;
    font-size: 13px;
}

.recentactivity4 {
    margin: -44px 0 10px 0;
    min-height: 400px;
    border-radius: 8px;
}

.slider1 {
    border-right: 1px solid #D3D9E9;
    border-radius: 8px;
}

.loanslider1 {
    border: 1px solid #0075FF3D;
    border-radius: 10px;
    border-right: none;
}

.loanslider2 {
    width: 150px;
    border: none;
    font-weight: bold;
}

.emi1 {
    color: #182D64;
    text-align: center;
}

.emi2 {
    color: white;
    width: fit-content;
    background: linear-gradient(118.49deg, #FF6A92 16.02%, #FF5C5D 90.75%);
    padding: 10px;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.loancount1 {
    position: relative;
    padding-bottom: 250px;
    border-radius: 8px;
    height: 400px;
}

.loancount2 {
    color: #182D64;
    margin-left: 10px;
}

.loancount3 {
    position: absolute;
    left: 20px;
    right: 40px;
    bottom: 0px;
    top: 80px;
}

.totalloan1 {
    color: #959FBA;
    margin-left: 5px;
}

.productpie1 {
    border-radius: 8px;
    height: 400px;
}

.productpie2 {
    position: relative;
    padding: 145px;
}

.productpie3 {
    position: absolute;
    left: 0px;
    right: 20px;
    bottom: 0px;
    top: 80px;
}

.productlead6 {
    width: 14px;
    height: 14px;
    border-radius: 6px;
    background-color: #267DFF;
    margin-top: 6px;
}

.productlead7 {
    color: #182D64;
    font-size: 14px;
    font-weight: 400;
}



/* .sidePanel {
    min-height: 100vh;
    background-color: #232F63;
    padding-top: 90px;
    padding-left: 20px;
}

.sideimage {
    margin-bottom: 60px;
    margin-top: 20px;
    margin-left: 15px;
}

.navbar1 {
    width: 100%;
    margin: auto;
}

.applybutton {
    color: #FF5C5D;
    border-color: #FF5C5D;
    background-color: white;
    width: 135;
    height: 38;
}

.date1 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
}

.addchapter {

    color: white;
    background: linear-gradient(270deg, #FF5C5D 0%, #FF6A92 100%);
    float: right;
}

.totalareacurve {
    border-radius: 10px;
    height: 160px;
    width: 140px;
}

.totalloanamount {
    color: #4B5563;
    font-size: 14px;
    font-weight: 400;
}

.totalloanamount1 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.review1 {
    border-radius: 16px;
    min-width: 270px;
    max-width: 350px;
    min-height: 400px;
    margin: auto;
    position: relative;
}

.review2 {
    position: absolute;
    top: 25%;
    left: 38%;
    width: fit-content;
}

.review3 {
    color: #4B5563;
    width: 82px;
    text-align: center;
    font-size: 12px;
}

.review4 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #267DFF;
    margin-top: 10px;
    margin-right: 20px;
}

.review5 {
    margin-top: 5px;
    margin-right: 20px;
}

.review6 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #70A9FF;
    margin-top: 10px;
    margin-right: 20px;
}

.review7 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ADCEFF;
    margin-top: 10px;
    margin-right: 20px;
}

.review8 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #DDECFF;
    margin-top: 10px;
    margin-right: 20px;
}

.review9 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF6A92;
    margin-top: 10px;
    margin-right: 20px;
}

.review10 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFADC3;
    margin-top: 10px;
    margin-right: 20px;
}

.review11 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFD6E1;
    margin-top: 10px;
    margin-right: 20px;
}

.review12 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFEFF2;
    margin-top: 10px;
    margin-right: 20px;
}

.review13 {
    position: absolute;
    top: 25%;
    left: 37%;
    width: fit-content;
    align-items: center;
}

.review14 {
    width: fit-content;
    font-size: 20px;
}

.review15 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFB828;
    margin-top: 10px;
    margin-right: 20px;
}

.review16 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFCF70;
    margin-top: 10px;
    margin-right: 20px;
}

.review17 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFE4AD;
    margin-top: 10px;
    margin-right: 20px;
}

.review18 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFF5E1;
    margin-top: 10px;
    margin-right: 20px;
}

.review19 {
    color: #4B5563;
    width: 82px;
    text-align: center;
    font-size: 12px;
}

.review20 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #54CBA1;
    margin-top: 10px;
    margin-right: 20px;
}

.review21 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #82D9BA;
    margin-top: 10px;
    margin-right: 20px;
}

.review22 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #B1E7D4;
    margin-top: 10px;
    margin-right: 20px;
}

.review23 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #DBF5EC;
    margin-top: 10px;
    margin-right: 20px;
}

.counttrend1 {
    position: relative;
    border-radius: 16px;
    padding-bottom: 250px;
    height: 450px;
}

.counttrend2 {
    color: #182D64;
    margin-left: 10px;
}

.counttrend3 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #267DFF
}

.counttrend4 {
    color: #959FBA;
    margin-left: 5px;
    font-weight: 700;
}

.counttrend5 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #DDECFF;
}

.counttrend6 {
    position: absolute;
    left: 20px;
    right: 40px;
    bottom: 0px;
    top: 80px;
}

.counttrend7 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #00C287;
}

.counttrend8 {
    color: #4B5563;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}

.counttrend9 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FF5C5D
} */

.filter1 {
    position: absolute;
    left: 10px;
    top: 5px;
    z-Index: 1;
}

.filter2 {
    border: none;
    padding-left: 40px;
}

.filter3 {
    color: #FF5C5D;
    border-color: #FF5C5D;
    background-color: white;
}

.add {

    color: white;
    background: linear-gradient(270deg, #FF5C5D 0%, #FF6A92 100%);
    float: right;
}

.info1 {
    border-radius: 16px;
    min-height: 200px;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
}

.totalloan1 {
    position: relative;
    padding-bottom: 250px;
    border-radius: 16px;
    height: 450px;
}

.totalloan2 {
    color: #182D64;
    margin-left: 10px;
}

.totalloan3 {
    background: #E9ECF58C;
    border-radius: "8px";
    bottom: 8px;
    left: 10%;
    right: 10%;
    height: 30px;
}

.totalloan4 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    margin-top: 5px;
    margin-right: 10px;
}

.totalloan5 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #00C287;
    margin-top: 5px;
    margin-right: 10px;
}

.totalloan6 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FF5C5D;
    margin-top: 5px;
    margin-right: 10px;
}

.productlead1 {
    padding-bottom: 250px;
    border-radius: 16px;
    height: 450px;
}

.productlead2 {
    color: #182D64;
    margin-left: 10px;
}

.productlead3 {
    font-size: 14px;
    font-weight: 400;
    color: #9291A5;
    margin: 0;
}

.productlead4 {
    font-size: 24px;
    font-weight: 700;
}

.productlead5 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.productlead6 {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #4A3AFF;
    margin-top: 6px;
}

.productlead7 {
    color: #182D64;
    font-size: 14px;
    font-weight: 400;
}

.productlead8 {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #2D5BFF;
    margin-top: 6px;
}

.productlead9 {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #93AAFD;
    margin-top: 6px;
}

.productlead10 {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #C6D2FD;
    margin-top: 6px;
}

.productlead11 {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #E5EAFC;
    margin-top: 6px;
}

.productlead12 {
    background-color: #D3D9E94D;
    border-radius: 12px;
}

.productlead13 {
    font-size: 25px;
    font-weight: 700;
}

.productlead14 {
    font-size: 14px;
    font-weight: 400;
    color: #4E5C7F;
}

.totallead1 {
    position: relative;
    padding-bottom: 250px;
    border-radius: 16px;
    height: 450px;
}

.totallead2 {
    color: #1F2937;
    margin-left: 10px;
}

.totallead3 {
    border-radius: 5px;
    height: 23px;
}

.totallead4 {
    font-size: 10px;
    font-weight: 400;
    border-radius: 5px;
    line-height: 5px;
}

.totallead5 {
    font-size: 10px;
    font-weight: 400;
    line-height: 5px;
}

.managerlead1 {
    position: relative;
    padding-bottom: 250px;
    border-radius: 16px;
    height: 450px;
}

.managerlead2 {
    color: #182D64;
    margin-left: 10px;
}

.my-container1 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}