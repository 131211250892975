$deepBlue: #007AFF;
$deepBlue20: rgba(44, 63, 139, 0.2);
$muteBlue: #3A4895;
$muteBlue20: rgba(58, 72, 149, 0.2);
$paleBlue: #5B73AB;
$paleBlue20: rgba(91, 115, 171, 0.2);
$stone: #E7E7E7;
$accentYellow: #ff7137;
$accentYellow-light: rgba(253, 188, 75, 0.7);

$whiteColor: #ffffff;
$whiteTransparent: rgba(255, 255, 255, 0.4);
$blackTransparent: rgba(0, 0, 0, 0.2);
$primaryColor: #337ab7;
$dangerColor: #dd4b39;
$successColor: #00a65a;
$warningColor: #f39c12;
$blackColor: #000000;
$lightStoneBlue: #ddebf7;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #FF6A92;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$mud: #727878;
$mudLight: #e2ebea;

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray; 
  &:hover{
    background: $gray-dark; 
  }
}

// Karla latin-ext
@font-face {
    font-family: 'Karla';
    font-style: normal;
    font-display: swap;
    src: local('Karla'), local('Karla-Regular'), url(https://fonts.gstatic.com/s/karla/v13/qkBbXvYC6trAT7RbLtyU5rZPoAU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// Karla latin 
@font-face {
    font-family: 'Karla';
    font-style: normal;
    font-display: swap;
    src: local('Karla'), local('Karla-Regular'), url(https://fonts.gstatic.com/s/karla/v13/qkBbXvYC6trAT7RVLtyU5rZP.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Roboto Condensed latin-ext
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-display: swap;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DQk6YvNkeg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
  // Roboto Condensed latin
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-display: swap;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQk6YvM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



.Karla{
    font-family: 'Karla' !important;
}
  
.Roboto-Condensed{
    font-family: 'Roboto Condensed' !important;
}

.credhub-theme {
    .dark-background, button.menu-hide-btn {
        background-color: $deepBlue;
    }

    .light-background {
        background-color: $paleBlue;
    }
}